import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { fontFamily, color, breakpoints, rem } from 'variables/_index'
import { ArrowRightCircle2 } from 'components/icon/index'
import classNames from 'classnames'

export interface CourseLinkCardType3Props {
  readonly imageElement: JSX.Element
  readonly courseName: string
  readonly href: string
  readonly courseType: 'kids' | 'primary' | 'junior' | 'high'
}

type CourseLinkCardType3PropsClassName = CourseLinkCardType3Props & {
  readonly className: string
}

function Component({
  className,
  imageElement,
  courseName,
  href,
  courseType,
}: CourseLinkCardType3PropsClassName) {
  return (
    <Link className={classNames(className, `is-${courseType}`)} to={href}>
      <div className="e-image">{imageElement}</div>
      <div className="e-body">
        <div className="e-text">
          <span className="e-name">{courseName}</span>
          <br />
          を詳しく見る
        </div>
        <div className="e-icon">
          <ArrowRightCircle2 backgroundColor="#0000FF" borderColor="#B9F0FF" />
        </div>
      </div>
    </Link>
  )
}

const StyledComponent = styled(Component)<CourseLinkCardType3PropsClassName>`
  display: block;
  font-family: ${fontFamily.zenKaku};
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  border-radius: ${rem(20 / 2)};
  overflow: hidden;

  @media ${breakpoints.lg} {
    border-radius: ${rem(20)};
  }

  > .e-image {
    width: 100%;

    @media ${breakpoints.lg} {
    }

    img {
      width: 100%;
      transition: transform 0.5s ease 0s;
    }
  }

  > .e-body {
    display: flex;
    align-items: center;
    padding: ${rem(8 / 2)} ${rem(16 / 2)} ${rem(15 / 2)};
    background-color: ${color.main};
    position: relative;
    font-size: ${rem(36 / 2)};
    color: #fff;
    font-weight: 700;

    @media ${breakpoints.lg} {
      padding: ${rem(10)} ${rem(16)} ${rem(12)};
      font-size: ${rem(18)};
    }

    > .e-text {
      font-size: ${rem(26 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(18)};
      }

      > .e-name {
        font-family: ${fontFamily.zenKaku};
        font-size: ${rem(36 / 2)};
        font-weight: 700;
        line-height: 1.39;
        color: #fff;

        @media ${breakpoints.lg} {
          font-size: ${rem(26)};
        }
      }
    }

    > .e-icon {
      margin-top: ${rem(5)};
      margin-left: auto;

      @media ${breakpoints.lg} {
        margin-top: ${rem(10)};
      }

      > svg {
        width: ${rem(54 / 2)};
        height: auto;
        display: block;

        @media ${breakpoints.lg} {
          width: ${rem(30)};
        }

        > g {
          > g {
            transition: all 0.3s;
          }

          > path {
            transition: all 0.3s;
          }
        }
      }
    }
  }

  &.is-kids .e-body {
    background-color: #76e2ff;
  }

  &.is-primary .e-body {
    background-color: #50d9ff;
  }

  &.is-junior .e-body {
    background-color: #00c8ff;
  }

  &.is-high .e-body {
    background-color: #00b2e3;
  }

  &:hover {
    > .e-image {
      img {
        transform: scale(1.1);
      }
    }

    > .e-body {
      > .e-icon {
        > svg {
          > g {
            > g {
              fill: #fff;
              stroke: rgb(185, 240, 255);
            }

            > path {
              stroke: #0000ff;
            }
          }
        }
      }
    }
  }
`

export const CourseLinkCardType3: (props: CourseLinkCardType3Props) => JSX.Element =
  StyledComponent

export default CourseLinkCardType3
