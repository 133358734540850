import React from 'react'
import styled from 'styled-components'
import { breakpoints, color, rem } from 'variables/_index'

export interface VoiceCardProps {
  readonly noProps?: string
  readonly title: string
  readonly imageElement: JSX.Element
  readonly grade: string
  readonly name: string
  readonly text: JSX.Element
}

type VoiceCardPropsClassName = VoiceCardProps & {
  readonly className: string
}

function Component({ className, title, imageElement, grade, name, text }: VoiceCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-title">
        <span className="e-title-marker">{title}</span>
      </div>
      <div className="e-meta">
        <div className="e-grade">{grade}</div>
        <div className="e-name">{name}</div>
      </div>
      <div className="e-image">{imageElement}</div>
      <div className="e-text-area">
        <div className="e-text">{text}</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<VoiceCardPropsClassName>`
  background-color: #fff;
  border-radius: ${rem(30 / 2)};
  border: 2px solid #333;
  position: relative;
  padding: ${rem(38 / 2)} ${rem(14)};

  @media ${breakpoints.lg} {
    border-radius: ${rem(30)};
    border-width: 3px;
    padding: ${rem(26)} ${rem(72)} ${rem(40)};
  }

  > .e-title {
    font-size: ${rem(40 / 2)};
    font-weight: 700;
    text-align: center;
    display: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1.3;
    letter-spacing: 0.02em;

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
    }

    > .e-title-marker {
      display: inline-block;
      padding-left: ${rem(10 / 2)};
      padding-right: ${rem(10 / 2)};
      background-image: linear-gradient(to top, #ffe10a 0%, #ffe10a ${rem(8)}, transparent ${rem(8)}, transparent 100%);

      @media ${breakpoints.lg} {
        padding-left: ${rem(10)};
        padding-right: ${rem(10)};
      }
    }
  }

  > .e-meta {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: ${rem(6 / 2)};
    font-size: ${rem(26 / 2)};
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(5)};
      font-size: ${rem(16)};
    }

    > .e-grade {
    }

    > .e-name {
      &::before {
        content: '｜';
      }
    }
  }

  > .e-image {
    margin-top: ${rem(30 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
    }

    img {
      width: ${rem(270 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(240)};
      }
    }
  }

  > .e-text-area {
    margin-top: ${rem(36 / 2)};
    text-align: justify;

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
    }

    > .e-text {
      display: inline;
      line-height: 1.83;
      font-size: ${rem(17)};
      font-weight: 500;
      color: ${color.main};
      border-bottom: 2px solid ${color.main};

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
      }
    }
  }
`

export const VoiceCard: (props: VoiceCardProps) => JSX.Element = StyledComponent

export default VoiceCard
