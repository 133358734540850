import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints, color } from 'variables/_index'

export interface SecretHeadProps {
  readonly secretNumber?: number
  readonly lead1: string
  readonly lead2: string
  readonly headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div'
}

type SecretHeadPropsClassName = SecretHeadProps & {
  readonly className: string
}

function Component({
  className,
  secretNumber,
  lead1,
  lead2,
  headingLevel = 'div',
}: SecretHeadPropsClassName) {
  const HeadingElement = headingLevel
  return (
    <HeadingElement className={className}>
      {secretNumber && (
        <div className="e-head">
          <span className="e-head-circle"></span>
          <span className="e-head-text1">秘</span>
          <span className="e-head-text2">密</span>
          <span className="e-head-text3">{secretNumber}</span>
        </div>
      )}
      <div className="e-lead1">{lead1}</div>
      <div className="e-lead2">{lead2}</div>
    </HeadingElement>
  )
}

const StyledComponent = styled(Component)<SecretHeadPropsClassName>`
  text-align: center;

  > .e-head {
    font-size: ${rem(46 / 2)};
    color: ${color.main};
    font-weight: 700;
    font-family: ${fontFamily.zenKaku};
    padding-top: ${rem(24 / 2 + 6)};
    position: relative;

    @media ${breakpoints.lg} {
      font-size: ${rem(38)};
      padding-top: ${rem(16 + 8)};
    }

    > .e-head-circle {
      width: ${rem(24 / 2)};
      height: ${rem(24 / 2)};
      border-radius: 50%;
      background-color: #0000ff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      animation: bound 1.2s infinite;

      @media ${breakpoints.lg} {
        width: ${rem(16)};
        height: ${rem(16)};
      }
    }

    > .e-head-text1,
    > .e-head-text2,
    > .e-head-text3 {
      display: inline-block;
      min-width: ${rem(45 / 2)};
      border-bottom: 3px solid ${color.main};
      padding-bottom: ${rem(4)};

      @media ${breakpoints.lg} {
        min-width: ${rem(40)};
      }
    }

    > .e-head-text2,
    > .e-head-text3 {
      margin-left: ${rem(32 / 2)};

      @media ${breakpoints.lg} {
        margin-left: ${rem(24)};
      }
    }
  }

  > .e-lead1 {
    font-size: ${rem(32 / 2)};
    font-weight: 500;
    font-family: ${fontFamily.zenKaku};
    margin-top: ${rem(40 / 2)};
    letter-spacing: 0.1em;
    white-space: nowrap;

    @media ${breakpoints.lg} {
      font-size: ${rem(28)};
      margin-top: ${rem(30)};
      letter-spacing: 0.2em;
    }
  }

  > .e-lead2 {
    margin-top: ${rem(20 / 2)};
    font-size: ${rem(46 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    letter-spacing: 0.2em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(22)};
      font-size: ${rem(38)};
    }
  }
`

export const SecretHead: (props: SecretHeadProps) => JSX.Element = StyledComponent

export default SecretHead
