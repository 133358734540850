import React from 'react'
import styled from 'styled-components'
import {
  innerWidth,
  rem,
  color,
  breakpoints,
  innerPadding,
  innerDummyPadding,
  fontFamily,
} from 'variables/_index'
import { siteStructure } from 'utils/site'
import ButtonExperienceType2 from 'components/atoms/buttonExperienceType2'

export interface CallToActionSimpleSectionProps {
  readonly contactId: string
  readonly girlImageElement: JSX.Element
  readonly illustImageElement: JSX.Element
}

type CallToActionSimpleSectionPropsClassName = CallToActionSimpleSectionProps & {
  readonly className: string
}

function Component({
  className,
  contactId,
  girlImageElement,
  illustImageElement,
}: CallToActionSimpleSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-image">{girlImageElement}</div>
        <div className="e-box">
          <div className="e-pop">
            実際<span className="is-small">の</span>授業
            <span className="is-small">を</span>無料<span className="is-small">で</span>
            体験!
          </div>
          <div className="e-lead">
            ｜<span className="is-em">最</span>
            <span className="is-em">短</span>
            <span className="is-em">翌</span>
            <span className="is-em">日</span>｜<span className="is-em">ス</span>
            <span className="is-em">マ</span>
            <span className="is-em">ホ</span>でもOK｜
          </div>
          <div className="e-button">
            <ButtonExperienceType2
              id={contactId}
              href={siteStructure.experience1.path}
              imageElement={illustImageElement}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CallToActionSimpleSectionPropsClassName>`
  padding-top: ${rem(20 / 2)};
  padding-bottom: ${rem(130 / 2)};
  background-color: ${color.accentCTA2};
  text-align: center;

  @media ${breakpoints.lg} {
    padding-top: ${rem(30)};
    padding-bottom: ${rem(110)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-image {
      text-align: center;

      img {
        width: ${rem(304 / 2)};
        height: auto;

        @media ${breakpoints.lg} {
          width: ${rem(198)};
        }
      }
    }

    > .e-box {
      display: inline-block;
      margin-top: ${rem(6)};
      padding: ${rem(30 / 2)} ${rem(10)} ${rem(48)};
      border: 2px solid #ffffff;
      border-radius: ${rem(20 / 2)};
      position: relative;

      @media ${breakpoints.lg} {
        margin-top: ${rem(6)};
        padding: ${rem(16)} ${rem(64)} ${rem(66)};
        border-radius: ${rem(20)};
        border-width: 3px;
      }

      > .e-pop {
        display: inline-flex;
        align-items: baseline;
        font-size: ${rem(46 / 2)};
        font-family: ${fontFamily.zenKaku};
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 1;
        white-space: nowrap;

        @media ${breakpoints.lg} {
          font-size: ${rem(40)};
        }

        > .is-small {
          font-size: ${rem(34 / 2)};

          @media ${breakpoints.lg} {
            font-size: ${rem(30)};
          }
        }

        &::before,
        &::after {
          content: '';
          height: ${rem(25)};
          width: ${rem(1.5)};
          background-color: #333;

          @media ${breakpoints.lg} {
            height: ${rem(42)};
            width: ${rem(3)};
          }
        }

        &::before {
          margin-right: ${rem(10)};
          transform: rotate(-20deg) translateY(${rem(6)});

          @media ${breakpoints.lg} {
            margin-right: ${rem(20)};
            transform: rotate(-20deg) translateY(${rem(6)});
          }
        }

        &::after {
          margin-left: ${rem(10)};
          transform: rotate(20deg) translateY(${rem(6)});

          @media ${breakpoints.lg} {
            margin-left: ${rem(20)};
            transform: rotate(20deg) translateY(${rem(6)});
          }
        }
      }

      > .e-lead {
        margin-top: ${rem(14)};
        font-weight: 700;
        font-size: ${rem(34 / 2)};
        color: ${color.accent3};
        white-space: nowrap;
        letter-spacing: 0.15em;
        line-height: 1;

        @media ${breakpoints.lg} {
          margin-top: ${rem(20)};
          font-size: ${rem(24)};
          letter-spacing: 0.4em;
        }

        > .is-em {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            bottom: calc(100% + ${rem(-1)});
            left: 50%;
            margin-left: ${rem(-8 / 2 / 2)};
            border-radius: 50%;
            transform: translateX(-0.07em);
            width: ${rem(8 / 2)};
            height: ${rem(8 / 2)};
            background-color: ${color.accent3};

            @media ${breakpoints.lg} {
              bottom: calc(100% + ${rem(-2)});
              margin-left: ${rem(-5 / 2)};
              width: ${rem(5)};
              height: ${rem(5)};
              transform: translateX(-0.2em);
            }
          }
        }
      }

      > .e-button {
        position: absolute;
        bottom: ${rem(-35)};
        left: 50%;
        transform: translateX(-50%);

        @media ${breakpoints.lg} {
          bottom: ${rem(-50)};
        }
      }
    }
  }
`

export const CallToActionSimpleSection: (
  props: CallToActionSimpleSectionProps
) => JSX.Element = StyledComponent

export default CallToActionSimpleSection
