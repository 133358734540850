import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints, color } from 'variables/_index'

export interface UsePortalCardProps {
  readonly stepNumber: number
  readonly leadText: string
  readonly popText?: string
  readonly imageElement: JSX.Element
}

type UsePortalCardPropsClassName = UsePortalCardProps & {
  readonly className: string
}

function Component({
  className,
  stepNumber,
  leadText,
  popText,
  imageElement,
}: UsePortalCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">{stepNumber}</div>
      <div className="e-lead">{leadText}</div>
      <div className="e-image">{imageElement}</div>
      {popText && <div className="e-pop">{popText}</div>}
    </div>
  )
}

const StyledComponent = styled(Component)<UsePortalCardPropsClassName>`
  > .e-head {
    width: ${rem(448 / 2)};
    margin-left: auto;
    margin-right: auto;
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(80 / 2)};
    font-weight: 500;
    line-height: 1;
    border-bottom: 2px solid ${color.main};
    text-align: center;
    color: ${color.main};
    padding-bottom: ${rem(12)};

    @media ${breakpoints.lg} {
      width: 100%;
      font-size: ${rem(64)};
      padding-bottom: ${rem(12)};
      border-bottom-width: 2px;
    }
  }

  > .e-lead {
    margin-top: ${rem(10)};
    font-size: ${rem(32 / 2)};
    font-weight: 500;
    line-height: 1;
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
      font-size: ${rem(24)};
    }
  }

  > .e-image {
    margin-top: ${rem(30 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(24)};
    }

    img {
      width: ${rem(550 / 2)};
      max-width: 100%;
      height: auto;

      @media ${breakpoints.lg} {
        width: ${rem(380)};
      }
    }
  }

  > .e-pop {
    margin-top: ${rem(30 / 2)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem(36 / 2)};
    font-weight: 500;

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
      font-size: ${rem(24)};
    }

    &::before,
    &::after {
      content: '';
      width: 2px;
      height: ${rem(20)};
      background-color: #333;
      flex-shrink: 0;

      @media ${breakpoints.lg} {
        height: ${rem(30)};
        border-width: 2px;
      }
    }

    &::before {
      margin-right: ${rem(14)};
      transform: rotate(-20deg);

      @media ${breakpoints.lg} {
        margin-right: ${rem(30)};
      }
    }

    &::after {
      margin-left: ${rem(14)};
      transform: rotate(20deg);

      @media ${breakpoints.lg} {
        margin-left: ${rem(28)};
      }
    }
  }
`

export const UsePortalCard: (props: UsePortalCardProps) => JSX.Element = StyledComponent

export default UsePortalCard
