import classNames from 'classnames'
import CourseLinkCardType2 from 'components/atoms/courseLinkCardType2'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import { siteStructure } from 'utils/site'
import {
  breakpoints,
  innerWidth,
  rem,
  innerPadding,
  innerDummyPadding,
  fontFamily,
} from 'variables/_index'

export interface CourseMenuSectionProps {
  readonly npProps?: string
}

type CourseMenuSectionPropsClassName = CourseMenuSectionProps & {
  readonly className: string
}

function Component({ className }: CourseMenuSectionPropsClassName) {
  const [isHoverKids, setIsHoverKids] = useState<boolean>(false)
  const [isHoverPrimary, setIsHoverPrimary] = useState<boolean>(false)
  const [isHoverJunior, setIsHoverJunior] = useState<boolean>(false)
  const [isHoverHigh, setIsHoverHigh] = useState<boolean>(false)

  const onMouseOverKids = () => {
    setIsHoverKids(true)
  }

  const onMouseOutKids = () => {
    setIsHoverKids(false)
  }

  const onMouseOverPrimary = () => {
    setIsHoverPrimary(true)
  }

  const onMouseOutPrimary = () => {
    setIsHoverPrimary(false)
  }

  const onMouseOverJunior = () => {
    setIsHoverJunior(true)
  }

  const onMouseOutJunior = () => {
    setIsHoverJunior(false)
  }

  const onMouseOverHigh = () => {
    setIsHoverHigh(true)
  }

  const onMouseOutHigh = () => {
    setIsHoverHigh(false)
  }

  return (
    <div className={className}>
      <div className="e-inner">
        <h2 className="e-head">
          <span className="e-head-circle"></span>
          選べるコース
        </h2>
        <div className="e-lead">
          各コースのカリキュラム内容について
          <br className="u-hidden-pc" />
          詳しく説明しています。
        </div>
        <div className="e-cards">
          <div className="e-card">
            <div className={classNames('e-card-illust', isHoverKids && 'is-hover')}>
              <StaticImage
                className="e-card-illust-kids"
                src="../../../assets/images/top/course-illust-kids.png"
                alt=""
                placeholder="none"
              />
            </div>
            <div
              className="e-card-link"
              onMouseOver={() => onMouseOverKids()}
              onMouseOut={() => onMouseOutKids()}
            >
              <CourseLinkCardType2
                imageElementPC={
                  <StaticImage
                    src="../../../assets/images/top/course-kids.png"
                    alt=""
                    placeholder="none"
                  />
                }
                imageElementSP={
                  <StaticImage
                    className="u-height-full"
                    src="../../../assets/images/top/course-kids-sp.png"
                    alt=""
                    placeholder="none"
                  />
                }
                courseName="こどもコース"
                courseYear="｜年長～小２｜"
                href={siteStructure.kids.path}
                courseType="kids"
              />
            </div>
          </div>
          <div className="e-card">
            <div className={classNames('e-card-illust', isHoverPrimary && 'is-hover')}>
              <StaticImage
                className="e-card-illust-primary"
                src="../../../assets/images/top/course-illust-primary.png"
                alt=""
                placeholder="none"
              />
            </div>
            <div
              className="e-card-link"
              onMouseOver={() => onMouseOverPrimary()}
              onMouseOut={() => onMouseOutPrimary()}
            >
              <CourseLinkCardType2
                imageElementPC={
                  <StaticImage
                    src="../../../assets/images/top/course-primary.png"
                    alt=""
                    placeholder="none"
                  />
                }
                imageElementSP={
                  <StaticImage
                    className="u-height-full"
                    src="../../../assets/images/top/course-primary-sp.png"
                    alt=""
                    placeholder="none"
                  />
                }
                courseName="小学生コース"
                courseYear="｜小３～小６｜"
                href={siteStructure.primary.path}
                courseType="primary"
              />
            </div>
          </div>
          <div className="e-card">
            <div className={classNames('e-card-illust', isHoverJunior && 'is-hover')}>
              <StaticImage
                className="e-card-illust-junior"
                src="../../../assets/images/top/course-illust-junior.png"
                alt=""
                placeholder="none"
              />
            </div>
            <div
              className="e-card-link"
              onMouseOver={() => onMouseOverJunior()}
              onMouseOut={() => onMouseOutJunior()}
            >
              <CourseLinkCardType2
                imageElementPC={
                  <StaticImage
                    src="../../../assets/images/top/course-junior.png"
                    alt=""
                    placeholder="none"
                  />
                }
                imageElementSP={
                  <StaticImage
                    className="u-height-full"
                    src="../../../assets/images/top/course-junior-sp.png"
                    alt=""
                    placeholder="none"
                  />
                }
                courseName="中学生コース"
                courseYear="｜中１～中３｜"
                href={siteStructure.junior.path}
                courseType="junior"
              />
            </div>
          </div>
          <div className="e-card">
            <div className={classNames('e-card-illust', isHoverHigh && 'is-hover')}>
              <StaticImage
                className="e-card-illust-high"
                src="../../../assets/images/top/course-illust-high.png"
                alt=""
                placeholder="none"
              />
            </div>
            <div
              className="e-card-link"
              onMouseOver={() => onMouseOverHigh()}
              onMouseOut={() => onMouseOutHigh()}
            >
              <CourseLinkCardType2
                imageElementPC={
                  <StaticImage
                    src="../../../assets/images/top/course-high.png"
                    alt=""
                    placeholder="none"
                  />
                }
                imageElementSP={
                  <StaticImage
                    className="u-height-full"
                    src="../../../assets/images/top/course-high-sp.png"
                    alt=""
                    placeholder="none"
                  />
                }
                courseName="高校生コース"
                courseYear="｜高１～高３｜"
                href={siteStructure.high.path}
                courseType="high"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseMenuSectionPropsClassName>`
  @keyframes CourseMenuSway {
    0% {
      transform: translate(-50%, ${rem(0)}) rotate(0);
    }

    100% {
      transform: translate(calc(-50% + ${rem(20)}), ${rem(0)}) rotate(-10deg);
    }
  }

  padding-top: ${rem(180 / 2)};
  padding-bottom: ${rem(180 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(160)};
    padding-bottom: ${rem(160)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      padding-top: ${rem(24 / 2)};
      position: relative;
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(56 / 2)};
      font-weight: 700;
      text-align: center;

      @media ${breakpoints.lg} {
        padding-top: ${rem(16 + 2)};
        font-size: ${rem(46)};
      }

      > .e-head-circle {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        border-radius: 50%;
        background-color: #ff8c00;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        animation: bound 1.2s infinite;

        @media ${breakpoints.lg} {
          width: ${rem(16)};
          height: ${rem(16)};
        }
      }

      > .is-small {
        font-size: ${rem(36)};
      }
    }

    > .e-lead {
      margin-top: ${rem(60 / 2)};
      font-size: ${rem(36 / 2)};
      line-height: 1.58;
      font-weight: 500;
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(30)};
        font-size: ${rem(24)};
      }
    }

    > .e-cards {
      margin-top: ${rem(40 / 2)};
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: ${rem(40 / 2)};
      padding-left: ${rem(0)};
      padding-right: ${rem(0)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        gap: ${rem(40)} ${rem(20)};
        padding-left: ${rem(120)};
        padding-right: ${rem(120)};
      }

      > .e-card {
        width: 100%;
        flex-grow: 1;
        padding-top: ${rem(0)};
        position: relative;

        @media ${breakpoints.lg} {
          width: calc(50% - ${rem(40 / 2)});
          padding-top: ${rem(40)};
        }

        > .e-card-illust {
          display: none;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          top: ${rem(0)};

          @media ${breakpoints.lg} {
            display: block;
          }

          > .e-card-illust-kids {
            width: ${rem(40.84)};
            height: auto;
          }

          > .e-card-illust-primary {
            width: ${rem(38.32)};
            height: auto;
          }

          > .e-card-illust-junior {
            width: ${rem(43.9)};
            height: auto;
          }

          > .e-card-illust-high {
            width: ${rem(40)};
            height: auto;
          }

          &.is-hover {
            animation: CourseMenuSway 1.8s ease-in-out 0s infinite alternate;
          }
        }

        > .e-card-link {
        }
      }
    }
  }
`

export const CourseMenuSection: (props: CourseMenuSectionProps) => JSX.Element =
  StyledComponent

export default CourseMenuSection
