import React from 'react'
import styled from 'styled-components'
import { ButtonType3 } from 'components/atoms/index'
import { NewsCards, INewsArchive } from 'components/group/index'
import { breakpoints, innerWidth, rem, innerPadding, innerDummyPadding, color, fontFamily } from 'variables/_index'
import { siteStructure } from 'utils/site'

export interface NewsSectionProps {
  readonly newsNodes: Array<INewsArchive>
}

type NewsSectionPropsClassName = NewsSectionProps & {
  readonly className: string
}

function Component({ className, newsNodes }: NewsSectionPropsClassName) {
  return (
    <section className={className}>
      <div className="e-inner">
        <h2 className="e-head">
          <span className="e-title-circle"></span>
          お知らせ
        </h2>
        <div className="e-items">
          <NewsCards newsNodes={newsNodes} />
        </div>
        <div className="e-button">
          <ButtonType3 href={siteStructure.news.path} text="一覧を見る" size="small" />
        </div>
      </div>
    </section>
  )
}

const StyledComponent = styled(Component)<NewsSectionPropsClassName>`
  padding-top: ${rem(90 / 2)};
  padding-bottom: ${rem(120 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(160)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      padding-top: ${rem(24 / 2 + 2)};
      text-align: center;
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(56 / 2)};
      font-weight: 700;
      line-height: 1.36;
      color: ${color.main};
      position: relative;

      @media ${breakpoints.lg} {
        padding-top: ${rem(16 + 2)};
        font-size: ${rem(46)};
      }

      > .e-title-circle {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        border-radius: 50%;
        background-color: ${color.main};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        animation: bound 1.2s infinite;

        @media ${breakpoints.lg} {
          width: ${rem(16)};
          height: ${rem(16)};
        }
      }
    }

    > .e-items {
      margin-top: ${rem(60 / 2)};
      position: relative;

      @media ${breakpoints.lg} {
        margin-top: ${rem(60)};
      }
    }

    > .e-button {
      margin-top: ${rem(60 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
      }

      > .e-button-more {
      }
    }
  }
`

export const NewsSection: (props: NewsSectionProps) => JSX.Element = StyledComponent

export default NewsSection
