import CourseLinkCardType3 from 'components/atoms/courseLinkCardType3'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { siteStructure } from 'utils/site'
import {
  breakpoints,
  innerWidth,
  rem,
  innerPadding,
  innerDummyPadding,
  fontFamily,
} from 'variables/_index'

export interface CourseMenu2SectionProps {
  readonly npProps?: string
}

type CourseMenu2SectionPropsClassName = CourseMenu2SectionProps & {
  readonly className: string
}

function Component({ className }: CourseMenu2SectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <h2 className="e-head">
          <span className="e-head-circle"></span>
          コース<span className="is-small">を</span>詳しく見る
        </h2>
        <div className="e-cards">
          <div className="e-card">
            <CourseLinkCardType3
              imageElement={
                <StaticImage
                  src="../../../assets/images/top/course-kids.png"
                  alt=""
                  placeholder="none"
                />
              }
              courseName="こどもコース"
              href={siteStructure.kids.path}
              courseType="kids"
            />
          </div>
          <div className="e-card">
            <CourseLinkCardType3
              imageElement={
                <StaticImage
                  src="../../../assets/images/top/course-primary.png"
                  alt=""
                  placeholder="none"
                />
              }
              courseName="小学生コース"
              href={siteStructure.primary.path}
              courseType="primary"
            />
          </div>
          <div className="e-card">
            <CourseLinkCardType3
              imageElement={
                <StaticImage
                  src="../../../assets/images/top/course-junior.png"
                  alt=""
                  placeholder="none"
                />
              }
              courseName="中学生コース"
              href={siteStructure.junior.path}
              courseType="junior"
            />
          </div>
          <div className="e-card">
            <CourseLinkCardType3
              imageElement={
                <StaticImage
                  src="../../../assets/images/top/course-high.png"
                  alt=""
                  placeholder="none"
                />
              }
              courseName="高校生コース"
              href={siteStructure.high.path}
              courseType="high"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseMenu2SectionPropsClassName>`
  padding-top: ${rem(120 / 2)};
  padding-bottom: ${rem(120 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(120)};
    padding-bottom: ${rem(120)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      padding-top: ${rem(24 / 2 + 6)};
      position: relative;
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(56 / 2)};
      font-weight: 700;
      text-align: center;

      @media ${breakpoints.lg} {
        padding-top: ${rem(16 + 2)};
        font-size: ${rem(46)};
      }

      > .e-head-circle {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        border-radius: 50%;
        background-color: #ff8c00;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        animation: bound 1.2s infinite;

        @media ${breakpoints.lg} {
          width: ${rem(16)};
          height: ${rem(16)};
        }
      }

      > .is-small {
        font-size: ${rem(46 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(36)};
        }
      }
    }

    > .e-cards {
      margin-top: ${rem(60 / 2)};
      display: flex;
      flex-wrap: wrap;
      gap: ${rem(20 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        gap: ${rem(28)};
      }

      > .e-card {
        width: calc(50% - ${rem(20 / 2 / 2)});

        @media ${breakpoints.lg} {
          width: calc(25% - ${rem((28 * 3) / 4)});
        }
      }
    }
  }
`

export const CourseMenu2Section: (props: CourseMenu2SectionProps) => JSX.Element =
  StyledComponent

export default CourseMenu2Section
