import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { fontFamily, color, breakpoints, rem } from 'variables/_index'
import { ArrowRightCircle1 } from 'components/icon/index'
import classNames from 'classnames'

export interface CourseLinkCardType2Props {
  readonly imageElementPC: JSX.Element
  readonly imageElementSP: JSX.Element
  readonly courseName: string
  readonly courseYear?: string
  readonly href: string
  readonly courseType: 'kids' | 'primary' | 'junior' | 'high'
}

type CourseLinkCardType2PropsClassName = CourseLinkCardType2Props & {
  readonly className: string
}

function Component({
  className,
  imageElementPC,
  imageElementSP,
  courseName,
  courseYear,
  href,
  courseType,
}: CourseLinkCardType2PropsClassName) {
  return (
    <Link className={classNames(className, `is-${courseType}`)} to={href}>
      <div className={classNames('e-image', 'u-hidden-sp')}>{imageElementPC}</div>
      <div className={classNames('e-image', 'u-hidden-pc')}>{imageElementSP}</div>
      <div className="e-body">
        <div className="e-text">
          <div className="e-name">{courseName}</div>
          <div className="e-year">{courseYear}</div>
        </div>
        <div className="e-icon">
          <ArrowRightCircle1 backgroundColor="#0000FF" borderColor="#B9F0FF" />
        </div>
      </div>
    </Link>
  )
}

const StyledComponent = styled(Component)<CourseLinkCardType2PropsClassName>`
  display: flex;
  flex-direction: row-reverse;
  font-family: ${fontFamily.zenKaku};
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  border-radius: ${rem(20 / 2)};
  overflow: hidden;

  @media ${breakpoints.lg} {
    display: block;
    border-radius: ${rem(20)};
  }

  > .e-image {
    width: ${rem(265 / 2)};

    @media ${breakpoints.lg} {
      width: 100%;
    }

    img {
      width: 100%;
      transition: transform 0.5s ease 0s;
    }
  }

  > .e-body {
    width: calc(100% - ${rem(265 / 2)});
    padding: ${rem(20 / 2)} ${rem(20)} ${rem(14 / 2)};
    background-color: ${color.main};
    position: relative;
    text-align: center;

    @media ${breakpoints.lg} {
      width: 100%;
      display: flex;
      align-items: flex-end;
      padding: ${rem(10)} ${rem(20)} ${rem(20)};
      text-align: left;
      margin-top: -1px;
    }

    > .e-text {
      > .e-name {
        font-family: ${fontFamily.zenKaku};
        font-size: ${rem(42 / 2)};
        font-weight: 700;
        line-height: 1.39;
        color: #fff;

        @media ${breakpoints.lg} {
          font-size: ${rem(34)};
        }
      }

      > .e-year {
        font-family: ${fontFamily.zenKaku};
        font-size: ${rem(26 / 2)};
        font-weight: 700;
        line-height: 1.25;
        color: #fff;
        letter-spacing: 0.1em;
        margin-top: ${rem(5)};

        @media ${breakpoints.lg} {
          font-size: ${rem(18)};
          margin-top: ${rem(5)};
        }
      }
    }

    > .e-icon {
      margin-top: ${rem(12 / 2)};

      @media ${breakpoints.lg} {
        margin-left: auto;
        margin-top: ${rem(0)};
      }

      > svg {
        width: ${rem(54 / 2)};
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @media ${breakpoints.lg} {
          width: ${rem(70)};
        }

        > g {
          transition: all 0.3s;
        }

        > path {
          transition: all 0.3s;
        }
      }
    }
  }

  &.is-kids .e-body {
    background-color: #76e2ff;
  }

  &.is-primary .e-body {
    background-color: #50d9ff;
  }

  &.is-junior .e-body {
    background-color: #00c8ff;
  }

  &.is-high .e-body {
    background-color: #00b2e3;
  }

  &:hover {
    > .e-image {
      img {
        transform: scale(1.1);
      }
    }

    > .e-body {
      > .e-icon {
        > svg {
          > g {
            fill: #fff;
            stroke: rgb(185, 240, 255);
          }

          > path {
            stroke: #0000ff;
          }
        }
      }
    }
  }
`

export const CourseLinkCardType2: (props: CourseLinkCardType2Props) => JSX.Element =
  StyledComponent

export default CourseLinkCardType2
