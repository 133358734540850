import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints, color } from 'variables/_index'

export interface SecretBoxProps {
  readonly secretNumber?: number
  readonly text: string
}

type SecretBoxPropsClassName = SecretBoxProps & {
  readonly className: string
}

function Component({ className, secretNumber, text }: SecretBoxPropsClassName) {
  return (
    <div className={className}>
      {secretNumber && (
        <div className="e-head">
          秘密
          {secretNumber}
        </div>
      )}
      <div className="e-text">{text}</div>
    </div>
  )
}

const StyledComponent = styled(Component)<SecretBoxPropsClassName>`
  display: flex;
  align-items: center;
  min-width: ${rem(300)};
  padding: ${rem(10 / 2)} ${rem(50 / 2)};
  font-family: ${fontFamily.zenKaku};
  letter-spacing: 0.05em;
  line-height: 1.54;
  font-weight: 500;
  border-radius: ${rem(20 / 2)};
  border: 2px solid #333;
  background-color: #fff;
  text-align: center;

  @media ${breakpoints.lg} {
    display: block;
    padding: ${rem(20)} ${rem(10)} ${rem(30)};
    border-radius: ${rem(20)};
    border-width: 3px;
  }

  > .e-head {
    font-size: ${rem(40 / 2)};
    color: ${color.main};
    letter-spacing: 0.3em;
    font-weight: 700;
    white-space: nowrap;

    @media ${breakpoints.lg} {
      font-size: ${rem(36)};
    }
  }

  > .e-text {
    margin-left: ${rem(30 / 2)};
    font-size: ${rem(32 / 2)};
    font-weight: 700;
    font-family: ${fontFamily.zenKaku};
    white-space: nowrap;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      margin-top: ${rem(6)};
    }
  }
`

export const SecretBox: (props: SecretBoxProps) => JSX.Element = StyledComponent

export default SecretBox
