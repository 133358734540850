import React from 'react'
import styled from 'styled-components'
import CallToActionSingleSection from 'components/section/common/callToActionSimpleSection'
import MainVisualSection from 'components/section/top/mainVisualSection'
import NewsSection from 'components/section/top/newsSection'
import { INewsArchive } from 'components/group/newsCards'
import {} from 'variables/_index'
import VoiceSection from 'components/section/top/voiceSection'
import AboutSection from 'components/section/top/aboutSection'
import CourseMenu1Section from 'components/section/top/courseMenu1Section'
import CourseMenu2Section from 'components/section/top/courseMenu2Section'
import HowSection from 'components/section/top/howSection'
import Secret1Section from 'components/section/top/secret1Section'
import { Secret2Section } from 'components/section/top/secret2Section'
import Secret3Section from 'components/section/top/secret3Section'
import Secret0Section from 'components/section/top/secret0Section'
import { StaticImage } from 'gatsby-plugin-image'
import AnnounceSection from 'components/section/top/announceSection'
import BannerSection from 'components/section/top/bannerSection'

export interface HomeTemplateProps {
  readonly newsNodes: Array<INewsArchive>
}

type HomeTemplatePropsClassName = HomeTemplateProps & {
  readonly className: string
}

function Component({ className, newsNodes }: HomeTemplatePropsClassName) {
  return (
    <div className={className}>
      <MainVisualSection />
      {/* <AnnounceSection />*/}
      {/* <BannerSection /> */}
      <VoiceSection />
      <CallToActionSingleSection
        contactId="cta_content1_trial"
        girlImageElement={<StaticImage src="../assets/images/common/common-contact1.png" alt="" placeholder="none" />}
        illustImageElement={<StaticImage src="../assets/images/illustration/illustration16-1.png" alt="" placeholder="none" />}
      />
      <AboutSection />
      <CourseMenu1Section />
      <CallToActionSingleSection
        contactId="cta_content2_trial"
        girlImageElement={<StaticImage src="../assets/images/common/common-contact2.png" alt="" placeholder="none" />}
        illustImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
      />
      <Secret0Section />
      <Secret1Section />
      <CallToActionSingleSection
        contactId="cta_content3_trial"
        girlImageElement={<StaticImage src="../assets/images/common/common-contact3.png" alt="" placeholder="none" />}
        illustImageElement={<StaticImage src="../assets/images/illustration/illustration16-3.png" alt="" placeholder="none" />}
      />
      <Secret2Section />
      <CallToActionSingleSection
        contactId="cta_content4_trial"
        girlImageElement={<StaticImage src="../assets/images/common/common-contact4.png" alt="" placeholder="none" />}
        illustImageElement={<StaticImage src="../assets/images/illustration/illustration16-4.png" alt="" placeholder="none" />}
      />
      <Secret3Section />
      <CourseMenu2Section />
      <CallToActionSingleSection
        contactId="cta_content5_trial"
        girlImageElement={<StaticImage src="../assets/images/common/common-contact5.png" alt="" placeholder="none" />}
        illustImageElement={<StaticImage src="../assets/images/illustration/illustration16-5.png" alt="" placeholder="none" />}
      />
      <HowSection />
      <NewsSection newsNodes={newsNodes} />
    </div>
  )
}

const StyledComponent = styled(Component)<HomeTemplatePropsClassName>``

export const HomeTemplate: (props: HomeTemplateProps) => JSX.Element = StyledComponent

export default HomeTemplate
