import React from 'react'
import styled from 'styled-components'
import { breakpoints, innerWidth, rem, innerPadding, innerDummyPadding, fontFamily, color } from 'variables/_index'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import { StaticImage } from 'gatsby-plugin-image'
import VoiceCard from 'components/atoms/voiceCard'
import ArrowNextImage from 'assets/images/common/slide-arrow-next.png'
import ArrowPrevImage from 'assets/images/common/slide-arrow-prev.png'

export interface VoiceSectionProps {
  readonly npProps?: string
}

type VoiceSectionPropsClassName = VoiceSectionProps & {
  readonly className: string
}

function Component({ className }: VoiceSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <h2 className="e-head">
          <span className="e-head-circle"></span>
          <div className="e-head-image">
            <StaticImage src="../../../assets/images/top/voice-head.png" alt="テストの点数があがった！" placeholder="none" />
          </div>
          <div className="e-head-text">
            <span className="is-small">の</span>声<span className="is-space">続々!</span>
          </div>
        </h2>
        <div className="e-box-area">
          <div className="e-box">
            <div className="e-box-lead">
              定期テスト<span className="is-small">で</span>
              <br className="u-hidden-pc" />
              平均
              <span className="is-color">
                <span className="is-large">12</span>点<span className="is-dot">ア</span>
                <span className="is-dot">ッ</span>
                <span className="is-dot">プ</span>!
              </span>
            </div>
            <div className="e-box-attention">※2022年の中間テスト結果（中学2年生数学）</div>
          </div>
        </div>
        <div className="e-slide">
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={10}
            slidesPerView={1.5}
            centeredSlides={true}
            pagination={{ clickable: true }}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <VoiceCard
                title="数学32点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice15.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="K.Iさん"
                text={<>先生が自分に寄り添いながら教えてくれる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学32点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice16.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="Y.Kさん"
                text={<>学校と違って質問したらみっちり指導してもらえる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学29点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice13.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="S.Tさん"
                text={<>重点的に学習内容をみてくれるから安心!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学36点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice14.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="K.Iさん"
                text={<>講師の解説がわかりやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学32点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice1.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="M.Dさん"
                text={<>学校の授業を受けるのが最近楽しくなってきている!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学29点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice2.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="S.Iさん"
                text={<>授業中に自分の間違いに気づくことができた!</>}
              />
            </SwiperSlide>
            {/* <SwiperSlide>
              <VoiceCard
                title="英語20点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice3.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="A.Mさん"
                text={<>先生が楽しいし、わかりやすい!</>}
              />
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <VoiceCard
                title="数学24点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice4.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="K.Mさん"
                text={<>わからないところが質問しやすい!</>}
              />
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <VoiceCard
                title="数学18点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice5.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="T.Sさん"
                text={<>Fitが学校の授業の予習になり授業に取り組みやすい!</>}
              />
            </SwiperSlide> */}
            <SwiperSlide>
              <VoiceCard
                title="数学26点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice9.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学1年生"
                name="H.Hさん"
                text={<>Fitの先生の教え方がわかりやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学24点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice6.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="R.Hさん"
                text={<>いつでも直接教えてもらえるところがいい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="英語35点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice7.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="N.Oさん"
                text={<>どんなに簡単な問題でも、しっかり教えてくれる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学53点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice8.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学3年生"
                name="K.Iさん"
                text={<>自分のペースに合わせてやってくれる!</>}
              />
            </SwiperSlide>
            {/* <SwiperSlide>
              <VoiceCard
                title="数学26点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice9.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学1年生"
                name="H.Hさん"
                text={<>Fitの先生の教え方がわかりやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学20点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice10.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="S.Hさん"
                text={<>先生がわからない問題を優しく教えてくれる!</>}
              />
            </SwiperSlide> */}
            <SwiperSlide>
              <VoiceCard
                title="数学29点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice11.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="K.Nさん"
                text={<>先生の教え方がわかりやすく、学びやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                title="数学23点アップ!"
                imageElement={<StaticImage src="../../../assets/images/common/voice/voice12.png" alt="Fit NET STUDY" placeholder="none" />}
                grade="中学2年生"
                name="Y.Tさん"
                text={<>効率よく予習復習の勉強ができるようになった!</>}
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="e-attention">※点数アップ時の学年です。</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<VoiceSectionPropsClassName>`
  padding-top: ${rem(180 / 2)};
  padding-bottom: ${rem(180 / 2)};
  background-color: #00c8ff;
  overflow: hidden;

  @media ${breakpoints.lg} {
    padding-top: ${rem(160)};
    padding-bottom: ${rem(160)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      text-align: center;
      padding-top: ${rem(16 + 2)};
      position: relative;
      line-height: 1;

      @media ${breakpoints.lg} {
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }

      > .e-head-circle {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        border-radius: 50%;
        background-color: #ff8c00;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        animation: bound 1.2s infinite;

        @media ${breakpoints.lg} {
          width: ${rem(16)};
          height: ${rem(16)};
        }
      }

      > .e-head-image {
        margin-bottom: ${rem(-10)};

        img,
        svg {
          width: ${rem(601.4 / 2)};
          height: auto;

          @media ${breakpoints.lg} {
            width: ${rem(496.9)};
          }
        }
      }

      > .e-head-text {
        margin-top: ${rem(10)};
        font-size: ${rem(56 / 2)};
        font-weight: 700;
        font-family: ${fontFamily.zenKaku};
        color: #fff;

        @media ${breakpoints.lg} {
          font-size: ${rem(46)};
          margin-top: ${rem(0)};
          margin-left: ${rem(12)};
          transform: translateY(-2px);
        }

        .is-small {
          font-size: ${rem(46 / 2)};

          @media ${breakpoints.lg} {
            font-size: ${rem(36)};
          }
        }

        .is-space {
          margin-left: 0.35em;
        }
      }
    }

    > .e-box-area {
      margin-top: ${rem(30 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(30)};
        padding-left: ${rem(30)};
        padding-right: ${rem(30)};
      }

      > .e-box {
        border-radius: ${rem(20 / 2)};
        border: 2px solid #fff;
        padding: ${rem(20 / 2)} ${rem(10)} ${rem(30 / 2)};
        text-align: center;

        @media ${breakpoints.lg} {
          border-radius: ${rem(20)};
          border-width: 3px;
          padding: ${rem(0)} ${rem(16)} ${rem(16)};
        }

        > .e-box-lead {
          font-size: ${rem(54 / 2)};
          font-weight: 700;
          font-family: ${fontFamily.zenKaku};
          color: #fff;
          line-height: 1.2;

          @media ${breakpoints.lg} {
            font-size: ${rem(40)};
          }

          .is-small {
            font-size: ${rem(44 / 2)};

            @media ${breakpoints.lg} {
              font-size: ${rem(32)};
            }
          }

          .is-large {
            font-size: ${rem(83 / 2)};

            @media ${breakpoints.lg} {
              font-size: ${rem(60)};
            }
          }

          .is-color {
            color: #ffe10a;
          }

          .is-dot {
            position: relative;

            &::after {
              content: '';
              position: absolute;
              width: ${rem(12 / 2)};
              height: ${rem(12 / 2)};
              background-color: currentColor;
              border-radius: 50%;
              top: ${rem(-3)};
              left: 50%;
              transform: translate(-50%);

              @media ${breakpoints.lg} {
                width: ${rem(8)};
                height: ${rem(8)};
                top: ${rem(0)};
              }
            }
          }
        }

        > .e-box-attention {
          margin-top: ${rem(6)};
          font-size: ${rem(24 / 2)};
          color: #fff;

          @media ${breakpoints.lg} {
            margin-top: ${rem(0)};
            font-size: ${rem(14)};
          }
        }
      }
    }

    > .e-slide {
      margin-top: ${rem(80 / 2)};
      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);

      @media ${breakpoints.lg} {
        width: auto;
        margin-left: 0;
        margin-right: 0;
        margin-top: ${rem(60)};
        padding-left: ${rem(30)};
        padding-right: ${rem(30)};
        overflow: hidden;
      }

      .swiper {
        padding-bottom: ${rem(50)};

        @media ${breakpoints.lg} {
          padding-bottom: ${rem(50)};
          overflow: visible;
        }
      }

      .swiper-slide {
        height: auto;

        > [class^='voiceCard'] {
          height: 100%;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: ${rem(90 / 2)};
        height: ${rem(90 / 2)};
        margin-top: ${rem(-45)};

        @media ${breakpoints.lg} {
          width: ${rem(60)};
          height: ${rem(60)};
          margin-top: ${rem(-45)};
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }

      .swiper-button-prev {
        background-image: url(${ArrowPrevImage});
        background-repeat: no-repeat;
        background-size: contain;
        left: auto;
        right: calc(50% + 28.533vw);

        @media ${breakpoints.lg} {
          right: auto;
          left: ${rem(-30)};
        }
      }

      .swiper-button-next {
        background-image: url(${ArrowNextImage});
        background-repeat: no-repeat;
        background-size: contain;
        right: auto;
        left: calc(50% + 28.533vw);

        @media ${breakpoints.lg} {
          left: auto;
          right: ${rem(-30)};
        }
      }

      .swiper-pagination {
        bottom: ${rem(0)};
      }

      .swiper-pagination-bullet {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        background-color: #fff;
        opacity: 1;
        margin-right: ${rem(12 / 2)};
        margin-left: ${rem(12 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(8)};
          height: ${rem(8)};
          margin-right: ${rem(6)};
          margin-left: ${rem(6)};
        }

        &.swiper-pagination-bullet-active {
          background-color: #ffe10a;
        }
      }
    }

    > .e-attention {
      margin-top: ${rem(14 / 2)};
      font-size: ${rem(20 / 2)};
      color: #fff;
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(14)};
        font-size: ${rem(13)};
      }
    }
  }
`

export const VoiceSection: (props: VoiceSectionProps) => JSX.Element = StyledComponent

export default VoiceSection
