import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  innerWidth,
  rem,
  innerPadding,
  innerDummyPadding,
  fontFamily,
} from 'variables/_index'
import SecretHead from './atom/secretHead'

export interface Secret1SectionProps {
  readonly npProps?: string
}

type Secret1SectionPropsClassName = Secret1SectionProps & {
  readonly className: string
}

function Component({ className }: Secret1SectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-head">
          <SecretHead
            secretNumber={1}
            lead1="｜学校の授業が理解できるようになる!｜"
            lead2="成績があがる仕組み"
            headingLevel="h3"
          />
        </div>
        <div className="e-illust">
          <StaticImage
            src="../../../assets/images/top/secret1-illust.png"
            alt=""
            placeholder="none"
          />
        </div>
        <div className="e-box">
          <div className="e-box-card">
            <div className="e-box-left">
              <StaticImage
                src="../../../assets/images/top/secret1-1.png"
                alt=""
                placeholder="none"
              />
            </div>
            <div className="e-box-right">
              Fitでは、学校進度・お子さまの理解度に合わせて授業を進めます。わからない問題は、その原因を見つけて基礎から丁寧な指導を行い、演習をくり返すから、自分で解けるようになります。
            </div>
          </div>
          <div className="e-step1">
            <StaticImage
              className="u-hidden-sp"
              src="../../../assets/images/top/secret1-2-pc.png"
              alt="「わからない」を発見→基礎からの丁寧な指導→くり返し演習"
              placeholder="none"
            />
            <StaticImage
              className="u-hidden-pc"
              src="../../../assets/images/top/secret1-2-sp.png"
              alt="「わからない」を発見→基礎からの丁寧な指導→くり返し演習"
              placeholder="none"
            />
          </div>
          <div className="e-arrow1">
            <svg
              height="33.926"
              viewBox="0 0 89.181 33.926"
              width="89.181"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m85 30-42.5-30-42.5 30"
                fill="none"
                stroke="#333"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
                transform="matrix(-1 0 0 -1 87.09 32.09)"
              />
            </svg>
          </div>
          <div className="e-step2">
            基礎学力がつき、学校の授業がもっと理解しやすくなる!
          </div>
          <div className="e-arrow2">
            <svg
              height="33.926"
              viewBox="0 0 89.181 33.926"
              width="89.181"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m85 30-42.5-30-42.5 30"
                fill="none"
                stroke="#333"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
                transform="matrix(-1 0 0 -1 87.09 32.09)"
              />
            </svg>
          </div>
          <div className="e-step3">
            <div className="e-step3-text">成績がアップする!</div>
            <div className="e-step3-image">
              <StaticImage
                src="../../../assets/images/top/secret1-3.png"
                alt=""
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Secret1SectionPropsClassName>`
  padding-top: ${rem(80 / 2)};
  padding-bottom: ${rem(180 / 2)};
  background-color: #ddf8ff;

  @media ${breakpoints.lg} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(160)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      text-align: center;
    }

    > .e-illust {
      margin-top: ${rem(30 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
      }

      img {
        width: ${rem(65.28 / 2)};
        height: auto;

        @media ${breakpoints.lg} {
          width: ${rem(59.32)};
        }
      }
    }

    > .e-box {
      margin-top: ${rem(40 / 2)};
      background: #fff;
      border-radius: ${rem(20 / 2)};
      border: 2px solid #333;
      padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(60 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        border-radius: ${rem(20)};
        padding: ${rem(100)} ${rem(80)} ${rem(100)};
        border-width: 3px;
      }

      > .e-box-card {
        display: flex;
        flex-wrap: wrap;
        gap: ${rem(40)};

        @media ${breakpoints.lg} {
          gap: ${rem(40 / 2)};
        }

        > .e-box-left {
          @media ${breakpoints.lg} {
            width: calc(50% - ${rem(40 / 2)});
          }
        }

        > .e-box-right {
          font-size: ${rem(32 / 2)};
          line-height: 2;

          @media ${breakpoints.lg} {
            width: calc(50% - ${rem(40 / 2)});
            font-size: ${rem(18)};
          }
        }
      }

      > .e-step1 {
        margin-top: ${rem(40 / 2)};
        margin-left: ${rem(-16 / 2)};
        margin-right: ${rem(-8 / 2)};
        text-align: center;

        @media ${breakpoints.lg} {
          margin-top: ${rem(60)};
          margin-left: ${rem(0)};
          margin-right: ${rem(0)};
        }

        img {
          width: 100%;
          height: auto;

          @media ${breakpoints.lg} {
            width: ${rem(572.68)};
          }
        }
      }

      > .e-step2,
      > .e-step3 {
        width: ${rem(500 / 2)};
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: ${rem(20 / 2)} ${rem(80 / 2)};
        text-align: center;
        border-radius: ${rem(16 / 2)};
        font-size: ${rem(36 / 2)};
        font-weight: 500;
        background-color: #ffe10a;

        @media ${breakpoints.lg} {
          width: ${rem(660)};
          padding: ${rem(10)} ${rem(26)};
          font-size: ${rem(24)};
          border-radius: ${rem(10)};
        }
      }

      > .e-step2 {
        margin-top: ${rem(40 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(30)};
        }
      }

      > .e-step3 {
        margin-top: ${rem(40 / 2)};
        margin-bottom: ${rem(340 / 2)};
        position: relative;

        @media ${breakpoints.lg} {
          margin-top: ${rem(106)};
          margin-bottom: ${rem(0)};
        }

        > .e-step3-text {
        }

        > .e-step3-image {
          width: ${rem(395.37 / 2)};
          position: absolute;
          bottom: auto;
          top: calc(100% + ${rem(30 / 2)});
          right: 50%;
          transform: translateX(50%);

          @media ${breakpoints.lg} {
            width: ${rem(210)};
            top: auto;
            bottom: 0;
            right: ${rem(40)};
            transform: translateX(0);
          }

          img {
            height: auto;
          }
        }
      }

      > .e-arrow1,
      > .e-arrow2 {
        text-align: center;

        svg {
          width: ${rem(136 / 2)};
          height: auto;

          @media ${breakpoints.lg} {
            width: ${rem(85)};
          }
        }
      }

      > .e-arrow1 {
        margin-top: ${rem(40 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(40)};
        }
      }

      > .e-arrow2 {
        margin-top: ${rem(40 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(30)};
        }
      }
    }
  }
`

export const Secret1Section: (props: Secret1SectionProps) => JSX.Element = StyledComponent

export default Secret1Section
