import React from 'react'
import styled from 'styled-components'
import { Scroll } from 'components/atoms/index'
import { Illustration } from 'components/group/index'
import { color, rem, breakpoints, fontFamily } from 'variables/_index'
import { LogoType3 } from 'components/icon/index'
import { StaticImage } from 'gatsby-plugin-image'
import LogoType2SPImage from 'assets/images/top/logo-type2-sp.webp'
import LogoTypePCPImage from 'assets/images/top/logo-type2-pc.webp'

export interface MainVisualSectionProps {
  readonly npProps?: string
}

type MainVisualSectionPropsClassName = MainVisualSectionProps & {
  readonly className: string
}

const Component = ({ className }: MainVisualSectionPropsClassName) => {
  return (
    <div className={className}>
      <div className="e-illustration">
        <Illustration />
      </div>
      <div className="e-inner">
        <div className="e-content">
          <h1 className="e-logo">
            <div className="e-logo-text">
              <div className="e-logo-box-text-anim">オンライン個別指導塾</div>
            </div>
            <picture className="e-logo-image">
              <source srcSet={LogoTypePCPImage} media={`${breakpoints.md}`} width="321" height="40" />
              <img className="e-logo-fit" src={LogoType2SPImage} alt="Fit NET STUDY" decoding="async" width="214" height="27" />
            </picture>
          </h1>
          <ul className="e-lists">
            <li className="e-list">年長〜小２</li>
            <li className="e-list">小３〜小６</li>
            <li className="e-list">中学生</li>
            <li className="e-list">高校生</li>
          </ul>
        </div>
        <div className="e-scroll">
          <Scroll targetID="about" />
        </div>
      </div>
    </div>
  )
}

const brandTextDurationTime = 1
const brandTextDelayTime = 3

const brandLogoDurationTime = 1
const brandLogoDelayTime = brandTextDurationTime + brandTextDelayTime

const yearDurationTime = 1
const yearDelayTime = brandTextDurationTime + brandTextDelayTime

const StyledComponent = styled(Component)<MainVisualSectionPropsClassName>`
  @keyframes fadeUpMainVisualSection {
    0% {
      opacity: 0;
      transform: translateY(${rem(24)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideInMainVisualSection {
    0% {
      transform: translateY(${rem(-422)});
    }

    100% {
      transform: translateY(${rem(0)});
    }
  }

  @keyframes fadeInMainVisualSection {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes slideTextX100Illustration {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  width: 100%;
  padding-bottom: ${rem(190 / 2)};
  overflow: hidden;

  @media ${breakpoints.lg} {
    padding-bottom: ${rem(120)};
  }

  > .e-illustration {
    min-height: ${rem(648 / 2)};
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: ${rem(370 / 2)};
      background-color: ${color.main};
      position: absolute;
      top: ${rem(205 / 2)};
      left: 0;

      @media ${breakpoints.lg} {
        height: ${rem(420)};
        top: 0;
      }
    }

    @media ${breakpoints.lg} {
      min-height: ${rem(536)};
    }
  }

  > .e-inner {
    position: relative;
    width: ${rem(1000)};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
    }

    > .e-content {
      margin-top: ${rem(40 / 2)};
      width: 100%;
      color: #fff;
      text-align: center;
      position: relative;
      z-index: 5;
      opacity: 0;
      animation: fadeUpIllustration 2.8s ease 0s forwards;

      @media ${breakpoints.lg} {
        margin-top: ${rem(26)};
      }

      > .e-logo {
        line-height: 1;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @media ${breakpoints.lg} {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        > .e-logo-text {
          width: ${rem(174)};
          margin-left: auto;
          margin-right: auto;
          font-size: ${rem(34 / 2)};
          font-weight: 700;
          font-family: ${fontFamily.zenKaku};
          color: #0028be;
          text-align: left;
          white-space: nowrap;

          @media ${breakpoints.lg} {
            width: auto;
            font-size: ${rem(32)};
            margin-right: ${rem(26)};
            margin-left: 0;
          }

          > .e-logo-box-text-anim {
            white-space: nowrap;
            width: 0;
            overflow: hidden;
            animation: slideTextX100Illustration ${brandTextDurationTime}s ease ${brandTextDelayTime}s forwards;
          }
        }

        > .e-logo-image {
          display: block;
          opacity: 0;
          animation: fadeInMainVisualSection ${brandLogoDurationTime}s ease ${brandLogoDelayTime}s forwards;
          margin-top: ${rem(8)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(0)};
          }

          .e-logo-fit {
            width: ${rem(430 / 2)};
            height: auto;
            margin: 0;

            @media ${breakpoints.lg} {
              width: ${rem(321.1)};
            }
          }
        }
      }

      > .e-lists {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin: ${rem(3)} 0 0;
        padding: 0;
        opacity: 0;
        animation: fadeInMainVisualSection ${yearDurationTime}s ease ${yearDelayTime}s forwards;

        @media ${breakpoints.lg} {
          margin-top: ${rem(8)};
        }

        > .e-list {
          display: flex;
          align-items: center;
          margin: 0;
          font-size: ${rem(22 / 2)};
          font-weight: bold;
          letter-spacing: 0.05em;
          color: ${color.text};

          @media ${breakpoints.lg} {
            font-size: ${rem(16)};
          }

          &:nth-child(n + 2) {
            &::before {
              content: '｜';
              display: block;
            }
          }

          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }

    > .e-scroll {
      display: none;
      position: absolute;
      bottom: ${rem(16)};
      left: ${rem(0)};
      z-index: 2;

      @media ${breakpoints.lg} {
        display: block;
      }
    }
  }
`

export const MainVisualSection: (props: MainVisualSectionProps) => JSX.Element = StyledComponent

export default MainVisualSection
