import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, innerWidth, rem, innerPadding, innerDummyPadding, fontFamily, color } from 'variables/_index'
import SecretHead from './atom/secretHead'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import TeacherCard from '../top/atom/teacherCard'
import ArrowNextImage from 'assets/images/common/slide-arrow-next.png'
import ArrowPrevImage from 'assets/images/common/slide-arrow-prev.png'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/css'

// 画像
import TeacherImage1 from 'assets/images/top/teacher/teacher1.png'
import TeacherImage2 from 'assets/images/top/teacher/teacher2.png'
import TeacherImage4 from 'assets/images/top/teacher/teacher4.png'
import TeacherImage5 from 'assets/images/top/teacher/teacher5.png'
import TeacherImage6 from 'assets/images/top/teacher/teacher6.png'
import TeacherImage7 from 'assets/images/top/teacher/teacher7.png'
import TeacherImage9 from 'assets/images/top/teacher/teacher9.png'
import TeacherImage10 from 'assets/images/top/teacher/teacher10.png'
import TeacherImage11 from 'assets/images/top/teacher/teacher11.png'
import TeacherImage12 from 'assets/images/top/teacher/teacher12.png'
import TeacherImage14 from 'assets/images/top/teacher/teacher14.png'
import TeacherImage15 from 'assets/images/top/teacher/teacher15.png'

export interface Secret3SectionProps {
  readonly npProps?: string
}

type Secret3SectionPropsClassName = Secret3SectionProps & {
  readonly className: string
}

function Component({ className }: Secret3SectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '0px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-head">
          <SecretHead secretNumber={3} lead1="｜やる気を引き出す!｜" lead2="お子さま専属の担任制度" headingLevel="h3" />
        </div>
        <div className="e-illust">
          <StaticImage src="../../../assets/images/top/secret3-illust.png" alt="" placeholder="none" />
        </div>
        <div className="e-box">
          <div className="e-box-card">
            <div className="e-box-left">
              <StaticImage src="../../../assets/images/top/secret3-1.png" alt="" placeholder="none" />
            </div>
            <div className="e-box-right">
              担任と講師がチーム体制で成績アップを目指します。
              <br />
              月に1回の面談をはじめ、チーム全員で連携を取りながら授業や学習アドバイスを行うので、勉強へのやる気が持続します。
            </div>
          </div>
        </div>
        <h4 ref={ref} className={classNames('e-pop', inView && 'is-in-view')}>
          講師を一部紹介!
        </h4>
        <div className="e-slide">
          <Splide
            aria-label="私のお気に入りの画像集"
            options={{
              autoplay: true,
              interval: 3000,
              type: 'loop',
              perPage: 4,
              speed: 800,
              perMove: 1,
              focus: 0,
              gap: '1.875rem',
              breakpoints: {
                740: {
                  perMove: 1,
                  perPage: 2,
                  gap: '1.25rem',
                },
              },
            }}
          >
            <SplideSlide>
              <TeacherCard
                title="榎本先生"
                imageElement={<img className="e-image" src={TeacherImage5} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="神戸大学在籍"
                text={<>生徒一人ひとりに寄り添って教えていきます!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="志賀先生"
                imageElement={<img className="e-image" src={TeacherImage10} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="九州大学在籍"
                text={<>楽しく学べるよう全力でサポートします!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="田中先生"
                imageElement={<img className="e-image" src={TeacherImage1} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="京都大学在籍"
                text={<>わかるまで丁寧に解説します!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="横井先生"
                imageElement={<img className="e-image" src={TeacherImage2} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="名古屋大学在籍"
                text={<>分かりやすく噛み砕いて説明します!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="岸本先生"
                imageElement={<img className="e-image" src={TeacherImage11} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="神戸大学在籍"
                text={<>楽しい授業を目標にしています!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="田村先生"
                imageElement={<img className="e-image" src={TeacherImage4} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="名古屋大学在籍"
                text={<>親しみやすい穏和な先生を目指しています!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="日下先生"
                imageElement={<img className="e-image" src={TeacherImage12} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="金沢医科大学在籍"
                text={<>夢を叶えるために一緒に頑張りましょう!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="村上先生"
                imageElement={<img className="e-image" src={TeacherImage6} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="名古屋市立大学在籍"
                text={<>一緒に頑張りましょう!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="小松先生"
                imageElement={<img className="e-image" src={TeacherImage7} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="東京工業大学在籍"
                text={<>丁寧にわかりやすく教えます!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="中川先生"
                imageElement={<img className="e-image" src={TeacherImage9} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="埼玉大学在籍"
                text={<>楽しく一緒に勉強しましょう!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="入鹿先生"
                imageElement={<img className="e-image" src={TeacherImage14} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="広島大学在籍"
                text={<>楽しい授業できる様に頑張っています!</>}
              />
            </SplideSlide>
            <SplideSlide>
              <TeacherCard
                title="中川先生"
                imageElement={<img className="e-image" src={TeacherImage15} alt="" width="120" height="120" decoding="async" loading="lazy" />}
                position="九州大学在籍"
                text={<>しっかり解説します、一緒に頑張りましょう!</>}
              />
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Secret3SectionPropsClassName>`
  padding-top: ${rem(180 / 2)};
  padding-bottom: ${rem(160 / 2)};
  background-color: #ddf8ff;
  overflow: hidden;

  @media ${breakpoints.lg} {
    padding-top: ${rem(160)};
    padding-bottom: ${rem(160)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      text-align: center;
    }

    > .e-illust {
      margin-top: ${rem(30 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
      }

      img {
        width: ${rem(67.13 / 2)};
        height: auto;

        @media ${breakpoints.lg} {
          width: ${rem(61.79)};
        }
      }
    }

    > .e-box {
      display: flex;
      margin-top: ${rem(40 / 2)};
      background: #fff;
      border-radius: ${rem(20 / 2)};
      border: 3px solid #333;
      padding: ${rem(80 / 2)} ${rem(60 / 2)} ${rem(80 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        border-radius: ${rem(20)};
        padding: ${rem(80)} ${rem(40)} ${rem(60)};
      }

      > .e-box-card {
        display: flex;
        flex-wrap: wrap;

        > .e-box-left {
          width: 100%;
          margin-left: ${rem(-10)};
          margin-right: ${rem(-10)};

          @media ${breakpoints.lg} {
            width: 50%;
            margin-left: ${rem(0)};
            margin-right: ${rem(0)};
          }
        }

        > .e-box-right {
          width: 100%;
          margin-top: ${rem(40 / 2)};
          font-size: ${rem(32 / 2)};
          line-height: 2;
          padding-left: ${rem(0)};
          padding-right: ${rem(0)};

          @media ${breakpoints.lg} {
            width: 50%;
            margin-top: ${rem(0)};
            font-size: ${rem(18)};
            padding-left: ${rem(20)};
            padding-right: ${rem(40)};
          }
        }
      }
    }

    > .e-pop {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: ${rem(120)};
      font-size: ${rem(40 / 2)};
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      color: ${color.main};
      letter-spacing: 0.2em;

      @media ${breakpoints.lg} {
        margin-top: ${rem(100)};
        font-size: ${rem(30)};
      }

      &::before,
      &::after {
        content: '';
        width: 2px;
        height: ${rem(30)};
        background-color: #333;

        @media ${breakpoints.lg} {
          width: 3px;
          height: ${rem(40)};
        }
      }

      &::before {
        margin-right: ${rem(20)};
        transform: rotate(-14deg);
      }

      &::after {
        margin-left: ${rem(20)};
        transform: rotate(14deg);
      }

      &.is-in-view {
        animation-name: heartBeat;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
      }
    }

    > .e-slide {
      margin-top: ${rem(40 / 2)};
      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      padding-left: ${rem(30)};
      padding-right: ${rem(30)};

      @media ${breakpoints.lg} {
        width: 100%;
        margin-top: ${rem(30)};
        margin-left: 0;
        margin-right: 0;
        padding-left: ${rem(29)};
        padding-right: ${rem(29)};
        overflow: hidden;
      }

      .e-image {
        margin: ${rem(20 / 2)} 0 0 0;

        text-align: center;

        width: ${rem(240 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(16)};
          width: ${rem(186)};
        }
      }

      .swiper,
      .splide {
        padding-bottom: ${rem(50)};
        overflow: visible;

        @media ${breakpoints.lg} {
          padding-bottom: ${rem(50)};
          overflow: visible;
        }
      }

      .swiper-slide,
      .splide__slide {
        height: auto;

        > [class^='teacherCard'] {
          height: 100%;
        }
      }

      .swiper-button-prev,
      .swiper-button-next,
      .splide__arrow--prev,
      .splide__arrow--next {
        width: ${rem(90 / 2)};
        height: ${rem(90 / 2)};
        margin-top: ${rem(-45)};
        opacity: 1;

        @media ${breakpoints.lg} {
          width: ${rem(58)};
          height: ${rem(58)};
          margin-top: ${rem(-45)};
        }

        svg {
          display: none;
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }

      .swiper-button-prev,
      .splide__arrow--prev {
        background-image: url(${ArrowPrevImage});
        background-repeat: no-repeat;
        background-size: contain;
        left: auto;
        right: calc(50% + 36.533vw);

        @media ${breakpoints.lg} {
          right: auto;
          left: ${rem(-29)};
        }
      }

      .swiper-button-next,
      .splide__arrow--next {
        background-image: url(${ArrowNextImage});
        background-repeat: no-repeat;
        background-size: contain;
        right: auto;
        left: calc(50% + 36.533vw);

        @media ${breakpoints.lg} {
          left: auto;
          right: ${rem(-29)};
        }
      }

      .swiper-pagination,
      .splide__pagination {
        bottom: ${rem(0)};
        padding: 0;
      }

      .swiper-pagination-bullet,
      .splide__pagination__page {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        background-color: #fff;
        opacity: 1;
        margin-top: 0;
        margin-right: ${rem(12 / 2)};
        margin-left: ${rem(12 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(8)};
          height: ${rem(8)};
          margin-right: ${rem(6)};
          margin-left: ${rem(6)};
        }

        &.swiper-pagination-bullet-active,
        &.is-active {
          background-color: ${color.main};
          transform: scale(1);
        }
      }
    }
  }
`

export const Secret3Section: (props: Secret3SectionProps) => JSX.Element = StyledComponent

export default Secret3Section
