import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints } from 'variables/_index'
import SecretBox from '../atom/secretBox'

export interface CourseJuniorHighSchoolCalcProps {
  readonly noProps?: string
}

type CourseJuniorHighSchoolCalcPropsClassName = CourseJuniorHighSchoolCalcProps & {
  readonly className: string
}

function Component({ className }: CourseJuniorHighSchoolCalcPropsClassName) {
  return (
    <div className={className}>
      <div className="e-secret-box">
        <SecretBox secretNumber={1} text="成績があがる仕組み" />
      </div>
      <div className="e-secret-plus">
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" stroke="#333" strokeWidth="3">
            <path d="m2598.495-11238h24" transform="translate(-2598.495 11250)" />
            <path d="m0 0h24" transform="matrix(0 1 -1 0 12 0)" />
          </g>
        </svg>
      </div>
      <div className="e-secret-box">
        <SecretBox secretNumber={2} text="つまずかないサポート" />
      </div>
      <div className="e-secret-plus">
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" stroke="#333" strokeWidth="3">
            <path d="m2598.495-11238h24" transform="translate(-2598.495 11250)" />
            <path d="m0 0h24" transform="matrix(0 1 -1 0 12 0)" />
          </g>
        </svg>
      </div>
      <div className="e-secret-box">
        <SecretBox secretNumber={3} text="お子さま専属の担任制度" />
      </div>
      <div className="e-secret-equal">
        <svg height="24" viewBox="0 0 14.2 24" width="14.2" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" stroke="#333" strokeWidth="3">
            <path d="m2500-14694v24" transform="matrix(-1 0 0 -1 2512.7 -14670)" />
            <path d="m2500-14694v24" transform="matrix(-1 0 0 -1 2501.5 -14670)" />
          </g>
        </svg>
      </div>
      <div className="e-secret-result">
        <div className="e-secret-result-text">テストの点数アップ!</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseJuniorHighSchoolCalcPropsClassName>`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.lg} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  > .e-secret-box {
  }

  > .e-secret-plus {
    margin-top: ${rem(10 / 2)};
    margin-bottom: ${rem(10 / 2)};

    @media ${breakpoints.lg} {
      flex-shrink: 0;
      margin-top: ${rem(0)};
      margin-bottom: ${rem(0)};
      margin-left: ${rem(8)};
      margin-right: ${rem(8)};
    }

    svg {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: ${rem(24)};
      height: auto;
    }
  }

  > .e-secret-equal {
    margin-top: ${rem(10 / 2)};
    margin-bottom: ${rem(10 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      width: 100%;
      margin-top: ${rem(16)};
      margin-bottom: ${rem(16)};
    }

    svg {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: ${rem(12)};
      height: auto;

      @media ${breakpoints.lg} {
        width: ${rem(12)};
      }
    }
  }

  > .e-secret-result {
    padding: ${rem(12 / 2)} ${rem(50)};
    background-color: #ffe10a;
    border-radius: ${rem(20 / 2)};
    border: 2px solid #333;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;

    @media ${breakpoints.lg} {
      padding: ${rem(12)} ${rem(50)};
      border-radius: ${rem(20)};
      border-width: 3px;
      width: fit-content;
    }

    > .e-secret-result-text {
      text-align: center;
      display: inline;
      font-size: ${rem(42 / 2)};
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      line-height: 1.2;

      @media ${breakpoints.lg} {
        font-size: ${rem(38)};
      }
    }
  }
`

export const CourseJuniorHighSchoolCalc: (props: CourseJuniorHighSchoolCalcProps) => JSX.Element = StyledComponent

export default CourseJuniorHighSchoolCalc
