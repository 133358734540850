import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  innerWidth,
  rem,
  innerPadding,
  innerDummyPadding,
  color,
  fontFamily,
} from 'variables/_index'
import SecretHead from './atom/secretHead'

export interface Secret2SectionProps {
  readonly npProps?: string
}

type Secret2SectionPropsClassName = Secret2SectionProps & {
  readonly className: string
}

function Component({ className }: Secret2SectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-head">
          <SecretHead
            secretNumber={2}
            lead1="｜授業じゃない日も安心!｜"
            lead2="つまずかないサポート"
            headingLevel="h3"
          />
        </div>
        <div className="e-illust">
          <StaticImage
            src="../../../assets/images/top/secret2-illust.png"
            alt=""
            placeholder="none"
          />
        </div>
        <div className="e-box">
          <div className="e-box-card">
            <div className="e-box-left">
              <StaticImage
                src="../../../assets/images/top/secret2-1.png"
                alt=""
                placeholder="none"
              />
            </div>
            <div className="e-box-right">
              お子さまの学習状況や理解度に応じて、授業以外の学習もしっかりサポート。
              <br />
              オンライン自習室・いつでも質問できる解説ルームなどのサポートルームで、お子さまの「わからない」を解決。
            </div>
          </div>
          <h4 className="e-box-head">
            目的に合わせた
            <br className="u-hidden-pc" />
            <span className="is-color">4つ</span>のサポートルーム
          </h4>
          <div className="e-box-rooms">
            <div className="e-box-room">スタディルーム</div>
            <div className="e-box-room">解説ルーム</div>
            <div className="e-box-room">単元テストルーム</div>
            <div className="e-box-room">担任ルーム</div>
          </div>
          <div className="e-box-more">さらに!</div>
          <h4 className="e-box-head2">
            授業を行うための
            <br className="u-hidden-pc" />
            学習システムも充実!
          </h4>
          <div className="e-box-card2">
            <div className="e-box-left">
              <StaticImage
                src="../../../assets/images/top/secret2-2.png"
                alt=""
                placeholder="none"
              />
            </div>
            <div className="e-box-right">
              1クリックでスムーズに授業やサポートルームに参加することができます。操作は簡単なので、1人でも学習に集中できる環境が作れます。
              <div className="e-box-right-image">
                <StaticImage
                  src="../../../assets/images/top/secret2-3.png"
                  alt=""
                  placeholder="none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Secret2SectionPropsClassName>`
  padding-top: ${rem(180 / 2)};
  padding-bottom: ${rem(180 / 2)};
  background-color: #ddf8ff;

  @media ${breakpoints.lg} {
    padding-top: ${rem(160)};
    padding-bottom: ${rem(160)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      text-align: center;
    }

    > .e-illust {
      margin-top: ${rem(30 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
      }

      img {
        width: ${rem(62.58 / 2)};
        height: auto;

        @media ${breakpoints.lg} {
          width: ${rem(57.6)};
        }
      }
    }

    > .e-box {
      margin-top: ${rem(40 / 2)};
      background: #fff;
      border-radius: ${rem(20 / 2)};
      border: 3px solid #333;
      padding: ${rem(80 / 2)} ${rem(60 / 2)} ${rem(80 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        border-radius: ${rem(20)};
        border-width: 3px;
        padding: ${rem(100)} ${rem(80)} ${rem(100)};
      }

      > .e-box-card {
        display: flex;
        flex-wrap: wrap;
        gap: ${rem(40)};

        @media ${breakpoints.lg} {
          gap: ${rem(40 / 2)};
        }

        > .e-box-left {
          width: 100%;

          @media ${breakpoints.lg} {
            width: calc(50% - ${rem(40 / 2)});
          }
        }

        > .e-box-right {
          width: 100%;
          font-size: ${rem(32 / 2)};
          line-height: 2;

          @media ${breakpoints.lg} {
            width: calc(50% - ${rem(40 / 2)});
            font-size: ${rem(18)};
          }
        }
      }

      > .e-box-head {
        margin-top: ${rem(40 / 2)};
        font-size: ${rem(36 / 2)};
        font-family: ${fontFamily.zenKaku};
        font-weight: 700;
        text-align: center;
        line-height: 1.35;

        @media ${breakpoints.lg} {
          margin-top: ${rem(40)};
          font-size: ${rem(28)};
        }

        .is-color {
          color: ${color.main};
        }
      }

      > .e-box-rooms {
        width: ${rem(400 / 2)};
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: ${rem(20 / 2)};
        margin-top: ${rem(20 / 2)};
        margin-left: auto;
        margin-right: auto;
        padding: ${rem(30 / 2)} ${rem(50 / 2)};
        border-radius: ${rem(20 / 2)};
        background-color: #ddf8ff;

        @media ${breakpoints.lg} {
          width: ${rem(660)};
          gap: ${rem(20)} ${rem(40)};
          margin-top: ${rem(20)};
          padding: ${rem(20)} ${rem(60)};
          border-radius: ${rem(10)};
        }

        > .e-box-room {
          width: 100%;
          padding: ${rem(10 / 2)};
          background-color: ${color.main};
          border-radius: ${rem(10 / 2)};
          font-size: ${rem(32 / 2)};
          line-height: 1.4;
          font-weight: 500;
          text-align: center;

          @media ${breakpoints.lg} {
            width: calc(50% - ${rem(40 / 2)});
            padding: ${rem(10)};
            font-size: ${rem(24)};
            border-radius: ${rem(10)};
          }
        }
      }

      > .e-box-more {
        width: fit-content;
        margin-top: ${rem(80 / 2)};
        margin-left: auto;
        margin-right: auto;
        font-size: ${rem(40 / 2)};
        font-family: ${fontFamily.zenKaku};
        font-weight: 700;
        text-align: center;
        color: ${color.main};
        font-weight: 700;
        letter-spacing: 0.2em;
        border-bottom: 3px solid ${color.main};

        @media ${breakpoints.lg} {
          margin-top: ${rem(100)};
          font-size: ${rem(30)};
        }
      }

      > .e-box-head2 {
        margin-top: ${rem(20 / 2)};
        font-size: ${rem(40 / 2)};
        font-family: ${fontFamily.zenKaku};
        font-weight: 700;
        letter-spacing: 0.1em;
        text-align: center;
        line-height: 1.5;

        @media ${breakpoints.lg} {
          margin-top: ${rem(10)};
          font-size: ${rem(30)};
          letter-spacing: 0.2em;
        }
      }

      > .e-box-card2 {
        display: flex;
        flex-wrap: wrap;
        gap: ${rem(40 / 2)};
        margin-top: ${rem(60 / 2)};

        @media ${breakpoints.lg} {
          gap: ${rem(40)};
          margin-top: ${rem(40)};
        }

        > .e-box-left {
          width: 100%;

          @media ${breakpoints.lg} {
            width: calc(50% - ${rem(40 / 2)});
          }
        }

        > .e-box-right {
          width: 100%;
          font-size: ${rem(32 / 2)};
          line-height: 2;

          @media ${breakpoints.lg} {
            width: calc(50% - ${rem(40 / 2)});
            font-size: ${rem(18)};
          }

          > .e-box-right-image {
            text-align: center;
            margin-top: ${rem(40 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(317)};
              margin-top: ${rem(50)};
            }

            img {
              width: ${rem(382 / 2)};
              height: auto;

              @media ${breakpoints.lg} {
                width: ${rem(317)};
              }
            }
          }
        }
      }
    }
  }
`

export const Secret2Section: (props: Secret2SectionProps) => JSX.Element = StyledComponent

export default Secret2Section
