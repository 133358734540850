import { LogoType1, LogoType3 } from 'components/icon'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  innerWidth,
  rem,
  innerPadding,
  innerDummyPadding,
  color,
  fontFamily,
} from 'variables/_index'
import UsePortalCard from './atom/usePortalCard'

export interface HowSectionProps {
  readonly npProps?: string
}

type HowSectionPropsClassName = HowSectionProps & {
  readonly className: string
}

function Component({ className }: HowSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-logo">
          <span className="e-logo-circle"></span>
          <StaticImage
            className="e-logo-fit"
            src="../../../assets/images/common/logo-type2.png"
            alt="Fit NET STUDY"
            placeholder="none"
          />
        </div>
        <h2 className="e-head">受講方法</h2>
        <div className="e-lead">
          インターネット環境があれば
          <br className="u-hidden-pc" />
          ご受講が可能です。
        </div>
        <div className="e-image">
          <StaticImage
            src="../../../assets/images/top/illustration104.png"
            alt=""
            placeholder="none"
          />
        </div>
        <div className="e-boxes">
          <div className="e-box">
            <h3 className="e-box-head">学習システムの使い方</h3>
            <div className="e-box1-content">
              <div className="e-box1-left">
                <UsePortalCard
                  stepNumber={1}
                  leadText="ログインして授業の予定を確認"
                  imageElement={
                    <StaticImage
                      src="../../../assets/images/top/how1-1.png"
                      alt=""
                      placeholder="none"
                    />
                  }
                />
              </div>
              <div className="e-box1-right">
                <UsePortalCard
                  stepNumber={2}
                  leadText="授業の入室ボタンをクリック"
                  imageElement={
                    <StaticImage
                      src="../../../assets/images/top/how1-2.png"
                      alt=""
                      placeholder="none"
                    />
                  }
                  popText="Zoomで授業スタート!"
                />
              </div>
            </div>
          </div>
          <div className="e-box">
            <h3 className="e-box-head">利用可能端末</h3>
            <div className="e-box2-content">
              <div className="e-box2-left">
                <StaticImage
                  src="../../../assets/images/top/how2-1.png"
                  alt=""
                  placeholder="none"
                />
              </div>
              <div className="e-box2-right">
                スマホ以外、ほぼすべての端末が利用できます。
                <br />
                （無料体験はスマホもOK!）
                <div className="e-devices">
                  <div className="e-device">
                    タブレット
                    <span className="is-small">(アンドロイドまたはiPad)</span>
                  </div>
                  <div className="e-device">
                    PC
                    <span className="is-small">（WindowsまたはMac）</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="e-box">
            <h3 className="e-box-head">端末のレンタル</h3>
            <div className="e-box3-content">
              <div className="e-box3-left">
                <StaticImage
                  src="../../../assets/images/top/how3-1.png"
                  alt=""
                  placeholder="none"
                />
              </div>
              <div className="e-box3-right">
                タブレットのレンタルもございます。無料体験時にご相談ください。
                <div className="e-box3-illust">
                  <StaticImage
                    src="../../../assets/images/top/how3-2.png"
                    alt=""
                    placeholder="none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<HowSectionPropsClassName>`
  padding-top: ${rem(180 / 2)};
  padding-bottom: ${rem(90 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(160)};
    padding-bottom: ${rem(80)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-logo {
      padding-top: ${rem(24 / 2 + 10)};
      text-align: center;
      position: relative;

      @media ${breakpoints.lg} {
        padding-top: ${rem(16 + 16)};
      }

      > .e-logo-circle {
        display: block;
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        border-radius: 50%;
        background-color: ${color.main};
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        animation: bound 1.2s infinite;

        @media ${breakpoints.lg} {
          width: ${rem(16)};
          height: ${rem(16)};
        }
      }

      img,
      svg {
        width: ${rem(384.36 / 2)};
        height: auto;

        @media ${breakpoints.lg} {
          width: ${rem(272.25)};
        }
      }
    }

    > .e-head {
      margin-top: ${rem(10 / 2)};
      text-align: center;
      font-size: ${rem(56 / 2)};
      font-weight: 700;
      font-family: ${fontFamily.zenKaku};
      color: ${color.main};

      @media ${breakpoints.lg} {
        margin-top: ${rem(10)};
        font-size: ${rem(46)};
      }
    }

    > .e-lead {
      margin-top: ${rem(60 / 2)};
      font-size: ${rem(36 / 2)};
      font-weight: 500;
      text-align: center;
      line-height: 1.58;

      @media ${breakpoints.lg} {
        margin-top: ${rem(30)};
        font-size: ${rem(24)};
      }
    }

    > .e-image {
      margin-top: ${rem(30 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
      }

      img {
        width: ${rem(549.61 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(525)};
        }
      }
    }

    > .e-boxes {
      margin-top: ${rem(60 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(60)};
      }

      > .e-box {
        border-radius: ${rem(20 / 2)};
        border: 2px solid ${color.main};

        @media ${breakpoints.lg} {
          border-radius: ${rem(20)};
          border-width: 3px;
        }

        &:nth-child(n + 2) {
          margin-top: ${rem(80 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(100)};
          }
        }

        > .e-box-head {
          text-align: center;
          padding: ${rem(40 / 2)} ${rem(10 / 2)};
          font-family: ${fontFamily.zenKaku};
          line-height: 1.2;
          font-size: ${rem(46 / 2)};
          font-weight: 700;
          border-bottom: 1px solid ${color.main};

          @media ${breakpoints.lg} {
            padding: ${rem(20)};
            font-size: ${rem(38)};
          }
        }

        > .e-box1-content {
          display: flex;
          flex-wrap: wrap;
          padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)};

          @media ${breakpoints.lg} {
            padding: ${rem(50)} ${rem(40)};
          }

          > .e-box1-left,
          > .e-box1-right {
            width: 100%;
            padding-left: ${rem(0)};
            padding-right: ${rem(0)};

            @media ${breakpoints.lg} {
              width: 50%;
              padding-left: ${rem(40)};
              padding-right: ${rem(40)};
            }
          }

          > .e-box1-left {
          }

          > .e-box1-right {
            margin-top: ${rem(40 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(0)};
            }
          }
        }

        > .e-box2-content {
          display: flex;
          flex-wrap: wrap;
          padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)};

          @media ${breakpoints.lg} {
            padding: ${rem(50)} ${rem(60)};
          }

          > .e-box2-left,
          > .e-box2-right {
            width: 100%;
            padding-left: ${rem(0)};
            padding-right: ${rem(0)};

            @media ${breakpoints.lg} {
              width: 50%;
              padding-left: ${rem(20)};
              padding-right: ${rem(20)};
            }
          }

          > .e-box2-left {
            text-align: center;

            img {
              width: ${rem(400)};
              max-width: 100%;
              height: auto;
            }
          }

          > .e-box2-right {
            font-size: ${rem(32 / 2)};
            margin-top: ${rem(40 / 2)};
            line-height: 2;

            @media ${breakpoints.lg} {
              margin-top: ${rem(0)};
              font-size: ${rem(18)};
            }

            > .e-devices {
              margin-top: ${rem(40 / 2)};

              @media ${breakpoints.lg} {
                margin-top: ${rem(36)};
              }

              > .e-device {
                padding: ${rem(16 / 2)} ${rem(5 / 2)} ${rem(18 / 2)};
                border-radius: ${rem(16 / 2)};
                background-color: #00c8ff;
                text-align: center;
                font-size: ${rem(32 / 2)};
                font-weight: 500;
                white-space: nowrap;

                @media ${breakpoints.lg} {
                  padding: ${rem(0)} ${rem(10)};
                  border-radius: ${rem(10)};
                  font-size: ${rem(24)};
                }

                &:nth-child(n + 2) {
                  margin-top: ${rem(20 / 2)};

                  @media ${breakpoints.lg} {
                    margin-top: ${rem(20)};
                  }
                }

                .is-small {
                  font-size: ${rem(28 / 2)};

                  @media ${breakpoints.lg} {
                    font-size: ${rem(18)};
                  }
                }
              }
            }
          }
        }

        > .e-box3-content {
          display: flex;
          flex-wrap: wrap;
          padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)};

          @media ${breakpoints.lg} {
            padding: ${rem(50)} ${rem(60)};
          }

          > .e-box3-left,
          > .e-box3-right {
            width: 100%;
            padding-left: ${rem(0)};
            padding-right: ${rem(0)};

            @media ${breakpoints.lg} {
              width: 50%;
              padding-left: ${rem(20)};
              padding-right: ${rem(20)};
            }
          }

          > .e-box3-left {
            text-align: center;

            img {
              width: ${rem(400)};
              max-width: 100%;
              height: auto;
            }
          }

          > .e-box3-right {
            font-size: ${rem(36 / 2)};
            margin-top: ${rem(40 / 2)};
            line-height: 2;

            @media ${breakpoints.lg} {
              font-size: ${rem(18)};
              margin-top: ${rem(0)};
            }

            > .e-box3-illust {
              margin-top: ${rem(40 / 2)};
              text-align: center;

              @media ${breakpoints.lg} {
                margin-top: ${rem(14)};
              }

              img {
                width: ${rem(364.29 / 2)};
                max-width: 100%;
                height: auto;

                @media ${breakpoints.lg} {
                  width: ${rem(264)};
                }
              }
            }
          }
        }
      }
    }
  }
`

export const HowSection: (props: HowSectionProps) => JSX.Element = StyledComponent

export default HowSection
