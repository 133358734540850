import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, color } from 'variables/_index'

export interface TeacherCardProps {
  readonly title: string
  readonly imageElement: JSX.Element
  readonly position: string
  readonly text: JSX.Element
}

type TeacherCardPropsClassName = TeacherCardProps & {
  readonly className: string
}

function Component({ className, title, imageElement, position, text }: TeacherCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-title">{title}</div>
      <div className="e-pos">{position}</div>
      {imageElement}
      <div className="e-text-area">
        <div className="e-text">{text}</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<TeacherCardPropsClassName>`
  background-color: ${color.main};
  border-radius: ${rem(30 / 2)};
  border: 2px solid #333;
  position: relative;
  padding: ${rem(38 / 2)} ${rem(20 / 2)};
  text-align: center;
  border: 2px solid #333;

  @media ${breakpoints.lg} {
    border-radius: ${rem(30 / 2)};
    border-width: 3px;
  }

  > .e-title {
    font-size: ${rem(36 / 2)};
    font-weight: 700;
    text-align: center;
    padding-left: ${rem(20 / 2)};
    padding-right: ${rem(20 / 2)};

    @media ${breakpoints.lg} {
      font-size: ${rem(28)};
    }
  }

  > .e-pos {
    margin-top: ${rem(10 / 2)};
    font-size: ${rem(26 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(5)};
      font-size: ${rem(18)};
    }
  }

  > .e-text-area {
    margin: ${rem(20 / 2)} ${rem(20 / 2)} 0 ${rem(20 / 2)};
    text-align: left;
    text-align: justify;

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
    }

    > .e-text {
      display: inline;
      line-height: 1.88;
      font-size: ${rem(34 / 2)};
      font-weight: 700;
      color: #fff;
      border-bottom: 2px solid currentColor;

      @media ${breakpoints.lg} {
        font-size: ${rem(22)};
      }
    }
  }
`

export const TeacherCard: (props: TeacherCardProps) => JSX.Element = StyledComponent

export default TeacherCard
