import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import { breakpoints, innerWidth, rem, innerPadding, innerDummyPadding, fontFamily, color, zIndex } from 'variables/_index'
import iconCheck from 'assets/images/icon/icon-check.png'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'
import AboutMovieModal from './group/aboutMovieModal'

export interface AboutSectionProps {
  readonly npProps?: string
}

type AboutSectionPropsClassName = AboutSectionProps & {
  readonly className: string
}

function Component({ className }: AboutSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '0px', // ref要素が現れてから0px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const { ref: movieRef, inView: movieInView } = useInView({
    rootMargin: '-100px',
    triggerOnce: false,
  })

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  // 動画モーダルを開く
  const handleOpen = () => {
    setIsModalOpen(true)
    document.body.style.overflow = 'hidden'
    document.documentElement.style.overflow = 'hidden'
  }

  // 動画モーダルを閉じる
  const handleClose = () => {
    setIsModalOpen(false)
    document.body.style.overflow = 'auto'
    document.documentElement.style.overflow = 'auto'
  }

  return (
    <div className={className}>
      <div className="e-inner">
        <h2 ref={ref} className={classNames('e-head', inView && 'is-in-view')}>
          <div className="e-head-text">
            <div className="e-head-circle"></div>
            Fit<span className="is-small">の</span>
            <br className="u-hidden-pc" />
            オンライン授業って
            <br />
            どうやるの？
          </div>
        </h2>
        {/* <h3 className="e-movie-head">
          実際<span className="is-small">の</span>授業<span className="is-small">の</span>
          様子はこちら
        </h3> */}
        {/* <div className="e-movie" onClick={() => handleOpen()}>
          <div className="e-movie-content">
            <div className="e-movie-logo">
              <StaticImage
                src="../../../assets/images/top/movie-logo.png"
                alt="Fit NET STUDY"
                placeholder="none"
              />
            </div>
            <div className="e-movie-button">
              <StaticImage
                src="../../../assets/images/top/movie-play.png"
                alt="Fit NET STUDY"
                placeholder="none"
              />
            </div>
            <div className="e-movie-text">動画を見る</div>
          </div>
        </div>
        <div className={classNames('e-movie-modal', isModalOpen && 'is-open')}>
          <div className="e-movie-modal-background"></div>
          <div className="e-movie-modal-content">
            <AboutMovieModal onClose={handleClose} />
          </div>
        </div> */}
        <div className="e-about-contents">
          <ul className="e-check-box-lists">
            <li className="e-check-box-list">講師１人に生徒２人までの個別指導</li>
            <li className="e-check-box-list">対面式授業だから、 わからない問題はその場で質問できる</li>
            <li className="e-check-box-list">画面上に問題を映しながら解説をするからわかりやすい</li>
          </ul>

          <div className="e-about-movie-area">
            <p className="e-about-movie-area-head">実際の授業の様子</p>

            <div className="e-about-movie" ref={movieRef} data-anim={movieInView}>
              <div className="e-about-movie-area-illust1">
                <StaticImage src="../../../assets/images/top/ill_compas.png" alt="" />
              </div>
              <iframe
                src="https://www.youtube-nocookie.com/embed/BNdjdVvzivg?si=JdwjoDCBNSiltfUt"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <div className="e-about-movie-area-illust2">
                <StaticImage src="../../../assets/images/top/ill_noteruler.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <h3 className="e-pop">
          <div className="e-pop-text">
            <span className="is-color">オンラインだから</span>
            <br className="u-hidden-pc" />
            自宅
            <span className="is-small">で</span>受講できる!
          </div>
        </h3>
        <div className="e-point-area">
          <div className="e-point-image">
            <StaticImage src="../../../assets/images/top/about2.png" alt="" placeholder="none" />
          </div>
          <ul className="e-point-lists">
            <li className="e-point-list">日本全国どこからでも受講可能</li>
            <li className="e-point-list">塾への送り迎えが不要</li>
            <li className="e-point-list">
              時間を有効に使えるから
              <br className="u-hidden-pc" />
              部活や習い事と両立できる
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<AboutSectionPropsClassName>`
  padding-top: ${rem(180 / 2)};
  padding-bottom: ${rem(180 / 2)};
  background-color: #ddf8ff;

  @media ${breakpoints.lg} {
    padding-top: ${rem(160)};
    padding-bottom: ${rem(160)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-size: ${rem(56 / 2)};
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
      color: #333;

      @media ${breakpoints.lg} {
        font-size: ${rem(46)};
      }

      > .e-head-text {
        position: relative;
        padding-top: ${rem(24 / 2 + 10)};

        @media ${breakpoints.lg} {
          padding-top: ${rem(16 + 10)};
        }

        > .is-small {
          font-size: ${rem(46 / 2)};

          @media ${breakpoints.lg} {
            font-size: ${rem(36)};
          }
        }

        > .e-head-circle {
          width: ${rem(24 / 2)};
          height: ${rem(24 / 2)};
          border-radius: 50%;
          background-color: #ff8c00;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0;
          animation: bound 1.2s infinite;

          @media ${breakpoints.lg} {
            width: ${rem(16)};
            height: ${rem(16)};
          }
        }
      }

      &::before,
      &::after {
        content: '';
        width: 2px;
        height: ${rem(88)};
        background-color: #333;

        @media ${breakpoints.lg} {
          width: 3px;
          height: ${rem(96)};
        }
      }

      &::before {
        margin-right: ${rem(20)};
        transform: rotate(-20deg);
      }

      &::after {
        margin-left: ${rem(20)};
        transform: rotate(20deg);
      }

      &.is-in-view {
        animation-name: heartBeat;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
      }
    }

    > .e-movie-head {
      margin-top: ${rem(60 / 2)};
      font-size: ${rem(46 / 2)};
      font-weight: 700;
      color: ${color.main};
      font-family: ${fontFamily.zenKaku};
      background-color: #fff;
      padding: ${rem(10)} ${rem(46 / 2)};
      text-align: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        font-size: ${rem(38)};
        padding: ${rem(10)} ${rem(108)};
      }

      &::after {
        content: '';
        height: ${rem(40 / 2)};
        width: ${rem(4 / 2)};
        background-color: #fff;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        @media ${breakpoints.lg} {
          height: ${rem(30)};
          width: ${rem(3)};
        }
      }

      > .is-small {
        font-size: ${rem(38 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
        }
      }
    }

    > .e-movie {
      margin-top: ${rem(60 / 2)};
      width: 100%;
      aspect-ratio: 630 / 428;
      background-color: #c3c3c3;
      border-radius: ${rem(20)};
      border: ${rem(4)} solid #fff;
      position: relative;
      cursor: pointer;

      @media ${breakpoints.lg} {
        margin-top: ${rem(70)};
        aspect-ratio: 1000 / 429.77;
      }

      > .e-movie-content {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        > .e-movie-logo {
          img,
          svg {
            width: ${rem(240.22 / 2)};
            height: auto;

            @media ${breakpoints.lg} {
              width: ${rem(192.15)};
            }
          }
        }

        > .e-movie-button {
          margin-top: ${rem(30 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(40)};
          }

          img,
          svg {
            width: ${rem(120 / 2)};
            height: auto;

            @media ${breakpoints.lg} {
              width: ${rem(110)};
            }
          }
        }

        > .e-movie-text {
          margin-top: ${rem(16 / 2)};
          color: #fff;
          font-size: ${rem(28 / 2)};
          font-weight: 700;
          letter-spacing: 0.2em;
          text-indent: 0.2em;
          line-height: 1;

          @media ${breakpoints.lg} {
            margin-top: ${rem(20)};
            font-size: ${rem(18)};
          }
        }
      }
    }

    > .e-movie-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: ${zIndex.modal};
      display: none;

      &.is-open {
        display: flex;
      }

      > .e-movie-modal-content {
        text-align: center;
      }
    }

    > .e-about-contents {
      margin-top: ${rem(60 / 2)};
      padding: ${rem(60 / 2)} ${rem(37 / 2)} ${rem(60 / 2)};
      background-color: #fff;
      border-radius: ${rem(30 / 2)};

      @media ${breakpoints.lg} {
        padding: ${rem(40)} ${rem(60)};
        border-radius: ${rem(20)};
        margin-top: ${rem(60)};
      }

      .e-about-movie-area {
        margin: ${rem(60 / 2)} 0 0 0;

        @media ${breakpoints.lg} {
          margin: ${rem(53.1)} 0 0 0;
        }

        .e-about-movie-area-head {
          margin: 0;
          text-align: center;
          font-size: ${rem(32 / 2)};
          font-weight: bold;
          font-family: ${fontFamily.zenKaku};
          letter-spacing: 0.05em;

          @media ${breakpoints.lg} {
            font-size: ${rem(24)};
          }
        }

        .e-about-movie {
          position: relative;
          max-width: ${rem(560 / 2)};
          margin: ${rem(20 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            max-width: ${rem(500)};
            margin: ${rem(17)} auto 0 auto;
          }

          iframe {
            border: none;
            width: 100%;
            margin: 0;
            aspect-ratio: 560 / 350;
          }

          &[data-anim='true'] {
            > .e-about-movie-area-illust1 {
              animation: slideFadeIn 1s 0s forwards;
            }

            > .e-about-movie-area-illust2 {
              animation: slideFadeIn 1s 0s forwards;
            }
          }
          .e-about-movie-area-illust1 {
            display: none;

            @media ${breakpoints.lg} {
              width: ${rem(100)};
              display: block;
              position: absolute;
              top: ${rem(120)};
              left: ${rem(-154)};
              opacity: 0;
              transform: translateY(${rem(20)});
            }
          }
          .e-about-movie-area-illust2 {
            display: none;

            @media ${breakpoints.lg} {
              width: ${rem(148.73)};
              display: block;
              position: absolute;
              top: ${rem(50)};
              right: ${rem(-180)};
              opacity: 0;
              transform: translateY(${rem(20)});
            }
          }
        }
      }

      > .e-check-box-lists {
        list-style: none;
        margin-left: 0;

        @media ${breakpoints.lg} {
          margin: 0 auto;
          max-width: ${rem(700)};
        }

        > .e-check-box-list {
          font-size: ${rem(36 / 2)};
          line-height: 1.63;
          position: relative;
          padding-left: ${rem(70 / 2)};
          font-weight: 500;
          margin: 0;

          @media ${breakpoints.lg} {
            font-size: ${rem(22)};
            padding-left: ${rem(50)};
          }

          &:nth-child(n + 2) {
            margin-top: ${rem(30 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(32)};
            }
          }

          &::before {
            content: '';
            width: ${rem(60.9 / 2)};
            height: ${rem(50 / 2)};
            position: absolute;
            left: ${rem(0)};
            top: ${rem(0)};
            background: url(${iconCheck}) no-repeat center center / contain;

            @media ${breakpoints.lg} {
              width: ${rem(41.4)};
              height: ${rem(33.9)};
              left: ${rem(0)};
            }
          }
        }
      }
    }

    > .e-pop {
      margin-top: ${rem(120 / 2)};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${rem(46 / 2)};
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      line-height: 1.3;
      text-align: center;
      color: #333;

      @media ${breakpoints.lg} {
        margin-top: ${rem(120)};
        font-size: ${rem(38)};
      }

      > .e-pop-text {
        .is-small {
          font-size: ${rem(38 / 2)};

          @media ${breakpoints.lg} {
            font-size: ${rem(32)};
          }
        }

        .is-color {
          color: ${color.main};
        }
      }

      &::before,
      &::after {
        content: '';
        width: 2px;
        height: ${rem(50)};
        background-color: #333;

        @media ${breakpoints.lg} {
          width: ${rem(3)};
          height: ${rem(45)};
        }
      }

      &::before {
        margin-right: ${rem(20)};
        transform: rotate(-20deg);
      }

      &::after {
        margin-left: ${rem(20)};
        transform: rotate(20deg);
      }
    }

    > .e-point-area {
      display: flex;
      flex-wrap: wrap;

      @media ${breakpoints.lg} {
        margin-top: ${rem(30)};
      }

      > .e-point-image {
        width: 100%;

        @media ${breakpoints.lg} {
          width: calc(420 / 1000 * 100%);
        }
      }

      > .e-point-lists {
        width: 100%;
        margin: ${rem(40 / 2)} 0 0 0;
        list-style: none;

        @media ${breakpoints.lg} {
          width: calc(550 / 1000 * 100%);
          margin-top: 0;
          margin-left: ${rem(30)};
        }

        > .e-point-list {
          margin-top: ${rem(40 / 2)};
          background-color: #fff;
          padding: ${rem(22 / 2)} ${rem(4)} ${rem(22 / 2)};
          text-align: center;
          font-size: ${rem(36 / 2)};
          border-radius: ${rem(16 / 2)};
          font-weight: 500;

          @media ${breakpoints.lg} {
            font-size: ${rem(22)};
            border-radius: ${rem(10)};
            padding: ${rem(23)} ${rem(4)} ${rem(24)};
          }

          &:nth-child(n + 2) {
            margin-top: ${rem(20 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(10)};
            }
          }
        }
      }
    }

    @keyframes slideFadeIn {
      0% {
        opacity: 0;
        transform: translateY(${rem(20)});
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`

export const AboutSection: (props: AboutSectionProps) => JSX.Element = StyledComponent

export default AboutSection
