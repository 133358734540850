import React from 'react'
import { graphql } from 'gatsby'
import { INewsArchive } from 'components/group/newsCards'
import Layout from 'layout/base'
import Seo from 'components/seo'
import HomeTemplate from 'templates/homeTemplate'
import globalTitle from '../utils/globalTitle'

interface IndexPageProps {
  data: {
    allWpPost: {
      nodes: Array<INewsArchive>
    }
  }
  location: Location
}

const IndexPage = ({ data, location }: IndexPageProps): JSX.Element => {
  return (
    <Layout pageType="home" isTopButtonGray={true}>
      <Seo
        title={globalTitle(location.pathname)}
        description="自宅でとことん成績アップできるオンライン個別指導塾Fit NET STUDYの公式サイトです。年長・小学生・中学生・高1・高2対象。"
        path={location.pathname}
      />
      <HomeTemplate newsNodes={data.allWpPost.nodes} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allWpPost(limit: 3, sort: { fields: date, order: DESC }) {
      nodes {
        content
        slug
        title
        databaseId
        date(formatString: "YYYY/MM/DD")
      }
    }
  }
`
