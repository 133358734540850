import { LogoType3 } from 'components/icon'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  innerWidth,
  rem,
  innerPadding,
  innerDummyPadding,
  fontFamily,
  color,
} from 'variables/_index'
import CourseJuniorHighSchoolCalc from './group/courseJuniorHighSchoolCalc'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

export interface Secret0SectionProps {
  readonly npProps?: string
}

type Secret0SectionPropsClassName = Secret0SectionProps & {
  readonly className: string
}

function Component({ className }: Secret0SectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '0px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-logo">
          <span className="e-logo-circle"></span>
          <StaticImage
            className="e-logo-fit"
            src="../../../assets/images/common/logo-type2.png"
            alt="Fit NET STUDY"
            placeholder="none"
          />
        </div>
        <div ref={ref} className={classNames('e-pop', inView && 'is-in-view')}>
          <h2 className="e-pop-text">
            成績<span className="is-small">が</span>アップする!
            <br className="u-hidden-pc" />
            &nbsp;
            <span className="is-color">
              3つ<span className="is-small">の</span>秘密
            </span>
          </h2>
        </div>
        <div className="e-image">
          <StaticImage
            src="../../../assets/images/top/secret0.png"
            alt=""
            placeholder="none"
          />
        </div>
        <div className="e-calc">
          <CourseJuniorHighSchoolCalc />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Secret0SectionPropsClassName>`
  padding-top: ${rem(160 / 2)};
  padding-bottom: ${rem(80 / 2)};
  background-color: #ddf8ff;

  @media ${breakpoints.lg} {
    padding-top: ${rem(160)};
    padding-bottom: ${rem(60)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-logo {
      padding-top: ${rem(24 / 2 + 20 / 2)};
      text-align: center;
      position: relative;

      @media ${breakpoints.lg} {
        padding-top: ${rem(16 + 8)};
      }

      > .e-logo-circle {
        display: block;
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        border-radius: 50%;
        background-color: #ff8c00;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        animation: bound 1.2s infinite;

        @media ${breakpoints.lg} {
          width: ${rem(16)};
          height: ${rem(16)};
        }
      }

      img,
      svg {
        width: ${rem(384.36 / 2)};
        height: auto;

        @media ${breakpoints.lg} {
          width: ${rem(272.25)};
        }
      }
    }

    > .e-pop {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: ${rem(20 / 2)};
      font-size: ${rem(56 / 2)};
      font-weight: 700;
      font-family: ${fontFamily.zenKaku};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
        font-size: ${rem(46)};
      }

      > .e-pop-text {
        font-size: ${rem(56 / 2)};
        font-family: ${fontFamily.zenKaku};
        line-height: 1.35;

        @media ${breakpoints.lg} {
          font-size: ${rem(46)};
        }

        .is-small {
          font-size: ${rem(46 / 2)};

          @media ${breakpoints.lg} {
            font-size: ${rem(36)};
          }
        }

        .is-color {
          color: ${color.main};
        }
      }

      &::before,
      &::after {
        content: '';
        width: 2px;
        height: ${rem(60)};
        background-color: #333;

        @media ${breakpoints.lg} {
          width: ${rem(3)};
          height: ${rem(50)};
        }
      }

      &::before {
        margin-right: ${rem(26)};
        transform: rotate(-16deg);
      }

      &::after {
        margin-left: ${rem(26)};
        transform: rotate(16deg);
      }

      &.is-in-view {
        animation-name: heartBeat;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
      }
    }

    > .e-image {
      margin-top: ${rem(60 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
      }

      img {
        width: ${rem(266 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(236)};
        }
      }
    }

    > .e-calc {
    }
  }
`

export const Secret0Section: (props: Secret0SectionProps) => JSX.Element = StyledComponent

export default Secret0Section
